import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import firebase from "../firebase"
import { checkUser } from "../utils/ApiList";

export default function PrivateRoutes() {
    const navigate = useNavigate();
    const [userData, setUserData] = React.useState(null)

    React.useEffect(() => {
        async function checkStatus() { await checkUser() }
        // const auth = getAuth()
        // onAuthStateChanged(auth, user => {
        //     console.log(user)
        // })

        checkStatus()
            .then(() => setUserData(localStorage.getItem('user')))
            .catch(e => {
                setUserData(null)
                localStorage.clear()
                navigate('/login')
            })

        const intervalID = setInterval(() => {
            checkStatus()
                .then(() => setUserData(localStorage.getItem('user')))
                .catch(e => {
                    setUserData(null)
                    localStorage.clear()
                    navigate('/login')
                })
        }, 100000)

        return () => clearInterval(intervalID);
    }, [])

    return (
        userData && <Outlet />
    )
}
