import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, MenuItem, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Close, FilterList, Place } from '@mui/icons-material';
import DashboardCardA from '../../components/DashboardCardA';
import DashboardCardB from '../../components/DashboardCardB';
import DashboardCardC from '../../components/DashboardCardC';
import DashboardTableA from '../../components/DashboardTableA';
import { useNavigate } from 'react-router';
import topaz from '../../components/TokenPass';
import { CircleMarker } from 'react-leaflet';
import Maps from './Maps';
import CardCount from './CardCount';
import { getDataVotersAnalytics } from '../../utils/ApiList';
import CardDiagram from './CardDiagram';
import CardDiagramLarge from './CardDiagramLarge';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const DashboardPage = () => {
    const navigate = useNavigate()
    const [targetData, setTargetData] = useState([])
    const [votersData, setVotersData] = useState([])
    const [arrKabupaten, setArrKabupaten] = useState([])
    const [kabupaten, setKabupaten] = useState('')
    const [selected, setSelected] = useState()

    const handleChangeKabupaten = (e) => {
        setKabupaten(e.target.value)
    }

    const handleClear = () => setSelected()

    const handleDetailMap = (e) => {
        navigate("/target-detail", { state: selected })
    }
    // console.log(votersData)
    // console.log(targetData)
    useEffect(() => {
        topaz.get(`${process.env.REACT_APP_API_URL}/api/target/page/1/max/1000`)
            .then(response => setTargetData(response.data.data))
            .catch(error => {
                console.error('Error fetching target data:', error);
            });

        getDataVotersAnalytics('')
            .then(response => setVotersData(response.data.data))
            .catch(error => {
                console.error('Error fetching target data:', error);
            });

        topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten/page/1/max/1000`)
            .then(response => setArrKabupaten(response.data.data))
            .catch(error => {
                console.error('Error fetching kabupaten data:', error);
            });
    }, []);
    return (
        <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12}>
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={6} lg={6}>
                        <CardCount type='target' total={targetData.reduce((count, x) => count + Number(x.target), 0) || 0} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <CardCount type='voters' total={votersData.maps && votersData.maps.reduce((count, x) => count + Number(x.total), 0) || 0} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <CardCount type='kabupaten' total={votersData.maps && votersData.maps.length || 0} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <CardCount type='kecamatan' total={targetData.length} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
                <Maps data={targetData} />
            </Grid>
            <Grid item xs={12} xl={6}>
                <CardDiagramLarge data={targetData} data2={votersData.supportDirection || []} title='Diagram Target Suara' />
            </Grid>
            {/* <Grid item lg={8} md={12} xs={12}>
                <Card sx={{ position: 'relative' }}>
                    <CardContent>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item sx={{ fontWeight: 'bold' }}>Peta Target</Grid>
                            <Grid item sx={{ minWidth: '200px' }}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    label='Kabupaten'
                                    onChange={handleChangeKabupaten}
                                    value={kabupaten}
                                    fullWidth
                                    select>
                                    <MenuItem value=''>SEMUA</MenuItem>
                                    {arrKabupaten.map(x =>
                                        <MenuItem key={x.id} value={x.name}>{x.name}</MenuItem>
                                    )}
                                </TextField></Grid>
                        </Grid>
                    </CardContent>
                    <Box sx={{ height: { xs: '50vh', lg: '60vh' } }}>
                        <MapContainer center={[-8.674330665629418, 117.3007753818085]} zoom={9} style={{ width: '100%', height: '100%', zIndex: 0 }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {targetData.filter(x => x.kabupaten.includes(kabupaten)).map(x =>
                                <CircleMarker
                                    key={x.id}
                                    center={[x.latitude, x.longitude]}
                                    radius={10}  // Set the radius of the circle marker
                                    pathOptions={{ fillColor: 'blue', color: 'blue' }}  // Set the fill and border color
                                    eventHandlers={{
                                        click: (e) => {
                                            setSelected(x);
                                        },
                                    }}
                                >
                                    <Tooltip>
                                        <div style={{ fontWeight: 'bold' }}>{x.kecamatan}</div>
                                        <div>Target: {x.target}</div>
                                        <div>Potensi: {x.potensi}</div>
                                    </Tooltip>
                                </CircleMarker>
                            )}
                        </MapContainer>
                    </Box>
                    {selected &&
                        <CardContent sx={{ position: 'absolute', zIndex: '10', paddingTop: '35px', bottom: '0', background: 'linear-gradient(to bottom, rgba(255,0,0,0) ,30px, #ffffff)', width: 'calc(100% - 32px)', }}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <div style={{ fontSize: '22px' }}>{selected.kecamatan}, KAB. {selected.kabupaten}</div>
                                </Grid>
                                <Grid item><IconButton onClick={handleClear}><Close /></IconButton></Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <div style={{ fontSize: '14px' }}>Jumlah desa</div>
                                    <div style={{ fontSize: '14px' }}>Jumlah tps</div>
                                    <div style={{ fontSize: '14px', marginTop: '16px' }}>Total DPT</div>
                                    <div style={{ fontSize: '14px' }}>Potensi</div>
                                    <div style={{ fontSize: '14px' }}>Target</div>
                                </Grid>
                                <Grid item xs={true}>
                                    <div style={{ fontSize: '14px' }}>: {selected.count_desa}</div>
                                    <div style={{ fontSize: '14px' }}>: {selected.count_tps}</div>
                                    <div style={{ fontSize: '14px', marginTop: '16px' }}>: {selected.dpt}</div>
                                    <div style={{ fontSize: '14px' }}>: {selected.potensi}</div>
                                    <div style={{ fontSize: '14px' }}>: {selected.target}</div>
                                </Grid>
                                <Grid item style={{ marginRight: '48px' }}>
                                    <div style={{ textAlign: 'center' }}>Kuadran</div>
                                    <div style={{ fontSize: '48px', fontWeight: 'bold', textAlign: 'center' }}>{selected.kuadran}</div>
                                    <Button variant='outlined' onClick={handleDetailMap}>Lihat Detail</Button>
                                </Grid>
                            </Grid>
                        </CardContent >
                    }
                </Card >
            </Grid>
            <Grid item xs={true}>
                <Grid container spacing={2}>
                    <Grid item xs={true}>
                        <DashboardCardA />
                    </Grid>
                    <Grid item xs={true}>
                        <DashboardCardB />
                    </Grid>
                    <Grid item xs={true}>
                        <DashboardCardC />
                    </Grid>
                    <Grid item xs={12}>
                        <DashboardTableA />
                    </Grid>

                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default DashboardPage