import topaz from './TokenPass';

export const getLocation = async (url, token) => {
  try {
    const response = await topaz.post(
      `${process.env.REACT_APP_API_URL}/api/auth/check-coordinate`,
      {
        link: url,
      }
    );

    const { lat, long } = response.data.data;

    return {
      latitude: lat,
      longitude: long,
    };
  } catch (error) {
    throw new Error('Invalid URL or API error');
  }
};

