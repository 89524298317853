import React from 'react'
import { useLocation } from 'react-router';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Card, CardContent, Grid } from '@mui/material';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const TargetDetailPage = () => {
  const { state } = useLocation();
  return (
    <Card>
      <MapContainer center={[state.latitude, state.longitude]} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '300px', maxHeight: '20vh', zIndex: 0 }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[state.latitude, state.longitude]}>
          <Tooltip>
            <div style={{ fontWeight: 'bold' }}>{state.kecamatan}</div>
          </Tooltip>
        </Marker>
      </MapContainer>
      <CardContent>
        <div style={{ fontSize: '36px', fontWeight: '700' }}>{state.kecamatan}</div>
        <div style={{ fontSize: '22px', fontWeight: '500', marginBottom: '16px' }}>KABUPATEN {state.kabupaten}</div>
        <Grid container alignItems='center'>
          <Grid item lg={2}>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>Jumlah Desa</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>Jumlah TPS</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>Jumlah DPT</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>Potensi</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>% Potensi</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>Target</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>% Target</div>
          </Grid>
          <Grid item xs={true}>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.count_desa}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.count_tps}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.dpt}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.potensi}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.persentase_potensi}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.target}</div>
            <div style={{ fontSize: '18px', fontWeight: '500' }}>: {state.persentase_target}</div>
          </Grid>
          <Grid item>
            <div style={{ width: '200px', height: '200px', border: '1px solid black', borderRadius: '8px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ textAlign: 'center', fontSize: '32px', fontWeight: '700' }}>KUADRAN</div>
              <div style={{ textAlign: 'center', fontSize: '72px', fontWeight: '600' }}>{state.kuadran}</div>
            </div>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

export default TargetDetailPage