import { Card, CardContent, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#00cbfe'];
const PASLON = ['Anies & Muhaimin', 'Ganjar & Mahfud', 'Prabowo & Gibran']

const CardDiagramLarge = ({ data, data2, title }) => {
    const [dataBar, setDataBar] = useState([])
    console.log(dataBar)
    // console.log(data2)

    useEffect(() => {
        let tempData = data2.map(x => { return { name: x.place_name, 0: x[0], 1: x[1], 2: x[2], 3: x[3] } })
        let dataBarNew = []

        dataBarNew = tempData.map(x => {
            let dataY = [0, 0]
            data.map(y => {
                if (y.kabupaten.toUpperCase() === x.name.toUpperCase()) {
                    dataY[0] += Number(y.potensi)
                    dataY[1] += Number(y.target)
                }
            })
            return ({
                name: x.name,
                'Potensi': dataY[0],
                'Target': dataY[1],
                'Capaian': Number(x[0]) + Number(x[1]) + Number(x[2]) + Number(x[3])
            })
        })
        setDataBar(dataBarNew)
    }, [data, data2])
    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', opacity: '0.7', minHeight: '48px' }}>{title}</Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={dataBar}>
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="0" orientation="left" stroke={COLORS[0]} />
                        {/* <YAxis yAxisId="1" orientation="right" stroke={COLORS[1]} />
                        <YAxis yAxisId="2" orientation="right" stroke={COLORS[2]} /> */}
                        <Legend align="left" iconSize={12}
                            payload={
                                ['Potensi', 'Target', 'Capaian'].map(
                                    (item, index) => ({
                                        id: item,
                                        type: "rect",
                                        value: `${item}`,
                                        color: COLORS[index]
                                    })
                                )
                            } />
                            <Tooltip />
                        <Bar dataKey="Potensi" fill={COLORS[0]} yAxisId="0">
                            {/* <LabelList dataKey="0" position="middle" fill='black' style={{ fontSize: '14px' }} /> */}
                        </Bar>
                        <Bar dataKey="Target" fill={COLORS[1]} yAxisId="0">
                            {/* <LabelList dataKey="1" position="middle" fill='black' style={{ fontSize: '14px' }} /> */}
                        </Bar>
                        <Bar dataKey="Capaian" fill={COLORS[2]} yAxisId="0">
                            {/* <LabelList dataKey="2" position="middle" fill='black' style={{ fontSize: '14px' }} /> */}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    )
}

export default CardDiagramLarge