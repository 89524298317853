import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import LoginPage from './pages/LoginPage'
import Layout from './components/Layout'

import TableG from './pages/tables/TableG'
import TableI from './pages/tables/TableI'
import TableU from './pages/tables/TableU'

import FormG from './pages/forms/general/FormG'
import FormI from './pages/forms/installation/FormI'
import FormU from './pages/forms/utilization/FormU'

import EditFormG from './pages/forms/general/EditFormG'
import EditFormI from './pages/forms/installation/EditFormI'
import EditFormU from './pages/forms/utilization/EditFormU'

import TargetDetailPage from './pages/TargetDetailPage'
import VotersDetailPage from './pages/VotersDetailPage'
import InstallDetailPage from './pages/InstallDetailPage'
import UtilDetailPage from './pages/UtilDetailPage'

import Logout from './components/Logout'
import PrivateRoutes from './components/PrivateRoutes'

import DataPage from './pages/DataPage/DataPage'
import VotersDataPage from './pages/DataPage/VotersDataPage'
import InstallDataPage from './pages/DataPage/InstallDataPage'
import UtilDataPage from './pages/DataPage/UtilDataPage'

import { ThemeProvider } from '@emotion/react'
import theme from './utils/theme'
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage'
import VotersAnalyticsPage from './pages/AnalyticsPage/VotersAnalyticsPage/VotersAnalyticsPage'
import ApkAnalyticsPage from './pages/AnalyticsPage/ApkAnalyticspage/ApkAnalyticsPage'
import { SnackbarProvider } from 'notistack'

const listRoute = [
    {
        path: '/dashboard',
        component: <DashboardPage />
    },
    {
        path: '/analytics/voters',
        component: <VotersAnalyticsPage />
    },
    {
        path: '/analytics/apk',
        component: <ApkAnalyticsPage />
    },
    {
        path: '/analytics',
        component: <AnalyticsPage />
    },
    {
        path: '/data-calon-pemilih',
        component: <DashboardPage />
    },
    {
        path: '/table-general',
        component: <TableG />
    },
    {
        path: '/form-general',
        component: <FormG />
    },
    {
        path: '/fg-edit/:id',
        component: <EditFormG />
    },
    {
        path: '/table-install',
        component: <TableI />
    },
    {
        path: '/form-install',
        component: <FormI />
    },
    {
        path: '/fi-edit/:id',
        component: <EditFormI />
    },
    {
        path: '/table-util',
        component: <TableU />
    },
    {
        path: '/form-util',
        component: <FormU />
    },
    {
        path: '/fu-edit/:id',
        component: <EditFormU />
    },
    {
        path: '/target-detail',
        component: <TargetDetailPage />
    },
    {
        path: '/voters-detail',
        component: <VotersDetailPage />
    },
    {
        path: '/install-detail',
        component: <InstallDetailPage />
    },
    {
        path: '/util-detail',
        component: <UtilDetailPage />
    },
    {
        path: '/data/voters',
        component: <VotersDataPage />
    },
    {
        path: '/data/installation',
        component: <InstallDataPage />
    },
    {
        path: '/data/inspection',
        component: <UtilDataPage />
    },
    {
        path: '/data',
        component: <DataPage />
    },
    {
        path: '/logout',
        component: <Logout />
    },
]

const Apps = () => {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route element={<PrivateRoutes />}>
                            <Route element={<Layout />}>
                                {listRoute.map(x =>
                                    <Route key={x.path} path={x.path} element={x.component} />
                                )}
                            </Route>
                        </Route>
                        <Route path='*' element={<Navigate to='/dashboard' />} />
                    </Routes>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default Apps