import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Box, Grid, Badge, Avatar, IconButton, Card, CardContent, Typography, TextField, TextareaAutosize } from '@mui/material';
import Photo from '@mui/icons-material/Photo';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { format } from 'date-fns';
import topaz from '../../../components/TokenPass';

import C1 from '../../../assets/img/c1.jpg';
import C2 from '../../../assets/img/c2.jpg';
import C3 from '../../../assets/img/c3.jpg';
import C4 from '../../../assets/img/c4.jpg';
import C5 from '../../../assets/img/c5.jpg';
import C6 from '../../../assets/img/c6.jpg';

const FormEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [photoFile, setPhotoFile] = useState(null);
  const [editData, setEditData] = useState({
    id: id,
    pic_installation: '',
    pic_inspector: '',
    visit_date: '',
    inspect_date: '',
    apk_code: '',
    apk_condition: '',
    disapproval_sign: '',
    support_sign: '',
    photo: '',
    description: '',
    created_by: '',
  });
  console.log(editData);



  

  


 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization/id/${id}`);
      const data = response.data;

      const apiData = data.data[0];

      const formattedData = {
          
          ...apiData,
          pic_installation: apiData.pic_installation,
    pic_inspector: apiData.pic_inspector,
    visit_date: apiData.visit_date ? new Date(apiData.visit_date) : null,
    inspect_date: apiData.inspect_date ? new Date(apiData.inspect_date) : null,
    apk_code: apiData.apk_code,
    apk_condition: apiData.apk_condition,
    disapproval_sign: apiData.disapproval_sign,
    support_sign: apiData.support_sign,
    photo: getImagePath(apiData.photo),
    description: apiData.description,
    created_by: apiData.created_by,
        };
  
        setEditData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const getImagePath = (path) => {
    if (!path) {
      return ''; 
    }
  
    if (typeof path === 'string') {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path; 
      }
     
      return `${process.env.REACT_APP_API_URL}/${path}`;
    }
  
    try {
    
      return URL.createObjectURL(path);
    } catch (error) {
      console.error('Error creating object URL:', error);
      return ''; 
    }
  };
  
  

  const handleRadioChange = (e) => {
    const value = e.target.value;
    handleFormChange('apk_condition', value);
  };

  const handleSupportSignChange = (e) => {
    const value = e.target.value;
    handleFormChange('support_sign', value);
  };
  
  const handleDisapprovalSignChange = (e) => {
    const value = e.target.value;
    handleFormChange('disapproval_sign', value);
  };
  
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file); 
    setEditData({ ...editData, photo: file }); 
  };



  const handleDateChange = (newValue, field) => {
    let formattedValue;

    if (field === 'visit_date' || field === 'inspect_date') {
      formattedValue = format(newValue, 'yyyy-MM-dd HH:mm:ss');
    }

    setEditData({ ...editData, [field]: formattedValue });
  };
  
  const handleFormChange = (field, value) => {
    setEditData({ ...editData, [field]: value });
  };

  const handleFormEdit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
  
      for (const key in editData) {
        const value = editData[key];
  
        if (value !== undefined && value !== null) {
          if (key === 'visit_date' || key === 'inspect_date') {
            const formattedDate = format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
            formData.append(key, formattedDate);
          } else if (key === 'photo') {
           
            if (photoFile) {
              formData.append('photo', photoFile);
            }
          } else {
            formData.append(key, value.toString());
          }
        }
      }
  
      const confirmed = window.confirm('Data will be edited. Are you sure the data is exactly right?');
  
      if (confirmed) {
        const response = await topaz.put(`${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        window.alert('Data has been edited successfully.');
        console.log(response.data);
        navigate('/data/inspection');
      } else {
        window.alert('Data editing cancelled.');
      }
    } catch (error) {
      console.error('Error editing data:', error);
      window.alert(`Failed to edit data: ${error.message}`);
    }
  };
  
  
  
  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/inspection');
    }
  };
  
  

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
    <Grid item xs={12} sm={10} md={8}> 
      <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
        <CardContent>
            <form onSubmit={handleFormEdit}>
              <Grid container spacing={3} direction="column">
               
             

                
                
                <Grid item>
                  <Typography variant="h6" color="primary">PIC</Typography>
                </Grid>
              
                <Grid item>
                  <TextField
                   onChange={(e) => handleFormChange('pic_installation', e.target.value)}
                   value={editData ? editData.pic_installation : ''}
                   name="pic_installation"
                   label="PIC Pemasangan"
                   fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                   onChange={(e) => handleFormChange('pic_inspector', e.target.value)}
                   value={editData ? editData.pic_inspector : ''}
                   name="pic_inspector"
                   label="PIC Pengecekan"
                   fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="primary">Pemasangan & Pengecekan</Typography>
                </Grid>
                <Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      renderInput={(params) => <TextField {...params} />}
      label="Tanggal Kunjungan"
      value={editData.visit_date ? new Date(editData.visit_date) : null}
      onChange={(newValue) => handleDateChange(newValue, 'visit_date')}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>
<Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      renderInput={(params) => <TextField {...params} />}
      label="Tanggal Pengecekan"
      value={editData.inspect_date ? new Date(editData.inspect_date) : null}
      onChange={(newValue) => handleDateChange(newValue, 'inspect_date')}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>
<Grid item>
                  <TextField
                   onChange={(e) => handleFormChange('apk_code', e.target.value)}
                   value={editData ? editData.apk_code : ''}
                   name="apk_code"
                   label="Kode APK"
                   fullWidth
                  />
                </Grid>     
      <Grid item xs={12}>
                <RadioGroup
                  aria-label="apk_condition"
                  name="apk_condition"
                  value={editData.apk_condition} 
                  onChange={handleRadioChange}
                  row
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1" color="textSecondary">Kondisi APK :</Typography>
                    </Grid>
                    
                    <Grid item>
              <FormControlLabel value="Baik" control={<Radio />} label="Baik" />
            </Grid>
            <Grid item>
              <FormControlLabel value="Kurang Baik" control={<Radio />} label="Kurang Baik" />
            </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
               
                

               
      
      <Grid item>
                  <Typography variant="h6" color="primary">Informasi Tambahan</Typography>
                </Grid>
                <Grid item>
                      <Typography variant="body1" color="textSecondary">Mendukung :</Typography>
                    </Grid>
                <Grid item xs={12}>
                <RadioGroup
                  aria-label="support_sign"
                  name="support_sign"
                  value={editData.support_sign} 
                  onChange={handleSupportSignChange}
                 
                >
                  
                    
                    
                  <Grid container spacing={2} alignItems="center">
    <Grid item>
      <FormControlLabel
        value="1"
        control={<Radio />}
        label={
          <div>
            <img src={C1} alt="Anies Rasyid Baswedan" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C4} alt="Abdul Muhaimin Iskandar" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    
    <Grid item>
      <FormControlLabel
        value="2"
        control={<Radio />}
        label={
          <div>
            <img src={C3} alt="Prabowo Subianto Djojohadikusumo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C6} alt="Gibran Rakabuming Raka" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>

    <Grid item>
      <FormControlLabel
        value="3"
        control={<Radio />}
        label={
          <div>
            <img src={C2} alt="Ganjar Pranowo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C5} alt="Mohammad Mahfud Mahmodin" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    <Grid item>
      <FormControlLabel
        value="0"
        control={<Radio />}
        label="Netral"
      />
    </Grid>
  </Grid>
               
                </RadioGroup>
              </Grid>
              <Grid item>
                      <Typography variant="body1" color="textSecondary">Menolak :</Typography>
                    </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-label="disapproval_sign"
                  name="disapproval_sign"
                  value={editData.disapproval_sign} 
                  onChange={handleDisapprovalSignChange}
                 
                >
                  
                    
                    
                  <Grid container spacing={2} alignItems="center">
         <Grid item>
              <FormControlLabel value="Ada" control={<Radio />} label="Ada" />
            </Grid>
            <Grid item>
              <FormControlLabel value="Tidak" control={<Radio />} label="Tidak" />
            </Grid>
  </Grid>
               
                </RadioGroup>
              </Grid>


              <Grid item>
                    <div style={{
                      position: 'relative',
                      width: '50%',
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      padding: '16.5px 14px'
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: '-11px',
                        left: '-12px',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        background: 'white',
                        padding: '8px',
                        color: '#9a9a9a',
                        transform: 'translate(14px, -9px) scale(0.75)'
                      }}>Foto APK</div>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <>
                            <input
                              id='photo-upload'
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={handlePhotoChange}
                            />
                            <label htmlFor='photo-upload'>
                              <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                            </label>
                          </>
                        }
                      >
                        <Avatar src={getImagePath(editData.photo)} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>
  Foto APK
</Avatar>

</Badge>
                    </div>
                  </Grid>
                  <Grid item>
      <Grid item>
              <Typography variant="body1" color="textSecondary">Keterangan :</Typography>
            </Grid>
            <TextareaAutosize
  onChange={(e) => handleFormChange('description', e.target.value)}
  value={editData.description}
  name="description"
  aria-label="empty textarea"
  placeholder="Deskripsi"
  minRows={3}
  style={{ width: '100%' }}
/>

</Grid>


<Grid item>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
    <Button variant="outlined" onClick={handleCancel}>
                  Batal
                </Button>
    <Button variant="contained" type="submit">
        Kirim
      </Button>
      
      </Box>
    </Grid>
      
    </Grid>
              
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormEdit;