import { useEffect } from 'react'
import firebase from '../firebase'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        firebase.auth().signOut()
        navigate('/login')
    }, [])

    return null
}

export default Logout