import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Button, Box, Grid, Badge, Avatar, IconButton, Card, CardContent, Typography, Select, MenuItem, TextField } from '@mui/material';
import Photo from '@mui/icons-material/Photo';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import L from 'leaflet';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { format } from 'date-fns';
import topaz from '../../../components/TokenPass';

import C1 from '../../../assets/img/c1.jpg';
import C2 from '../../../assets/img/c2.jpg';
import C3 from '../../../assets/img/c3.jpg';
import C4 from '../../../assets/img/c4.jpg';
import C5 from '../../../assets/img/c5.jpg';
import C6 from '../../../assets/img/c6.jpg';

const FormEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [mapCoordinates, setMapCoordinates] = useState({ lat: 0, lng: 0 });
  const [personFile, setPersonFile] = useState(null);
  const [idFile, setIdFile] = useState(null);
  const [editData, setEditData] = useState({
    id: id,
    visit_date: '',
    gender: '',
    date_of_birth: '',
    installation_willingness_if_gp: '',
    count_equipment_given_if_gp: '',
    dusun: '',
    kelurahan: '',
    kecamatan: '',
    kabupaten: '',
    google_maps_link: '',
    province: '',
    religion: '',
    occupation: '',
    phone_number: '',
    email: '',
    support_direction: '',
    person_photo_if_gp: '',
    full_name: '',
    type_equipment_given_if_gp: '',
    id_card_photo_if_gp: '',
    id_card_number: '',
    family_card_number: '',
    rt: '',
    rw: '',
    longitude: '',
    latitude:'',
    education:'',
  });
  console.log(editData);

  const handleSupportDirectionChange = (event) => {
    const selectedValue = event.target.value;
  
    if (selectedValue === '0') {
     
      setEditData({
        ...editData,
        support_direction: selectedValue,
        installation_willingness_if_gp: '0',
        person_photo_if_gp: null,
        id_card_photo_if_gp: null,
        type_equipment_given_if_gp: null,
        count_equipment_given_if_gp: null,
      });
      setAcceptEULA(false);
    } else {
      setEditData({ ...editData, support_direction: selectedValue, installation_willingness_if_gp: '0' });
      setAcceptEULA(false);
    }
  };
  

  

  const [acceptEULA, setAcceptEULA] = useState(editData?.installation_willingness_if_gp !== null ? editData.installation_willingness_if_gp.toString() : '');



  useEffect(() => {
    setAcceptEULA(editData?.installation_willingness_if_gp || '');
  }, [editData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/id/${id}`);
      const data = response.data;

      const apiData = data.data[0];

      const formattedData = {
          
          ...apiData,
          visit_date: apiData.visit_date ? new Date(apiData.visit_date) : null,
          date_of_birth: apiData.date_of_birth ? new Date(apiData.date_of_birth) : null,
          installation_willingness_if_gp: apiData.installation_willingness_if_gp,
          count_equipment_given_if_gp: apiData.count_equipment_given_if_gp,
          dusun: apiData.dusun,
          kelurahan: apiData.kelurahan,
          kecamatan: apiData.kecamatan,
          kabupaten: apiData.kabupaten,
          google_maps_link: apiData.google_maps_link,
          province: apiData.province,
          religion: apiData.religion,
          occupation: apiData.occupation,
          phone_number: apiData.phone_number,
          email: apiData.email,
          support_direction: apiData.support_direction,
          person_photo_if_gp: getImagePath(apiData.person_photo_if_gp),
          full_name: apiData.full_name,
          type_equipment_given_if_gp: apiData.type_equipment_given_if_gp,
          id_card_photo_if_gp: getImagePath(apiData.id_card_photo_if_gp),
          id_card_number: apiData.id_card_number,
          family_card_number: apiData.family_card_number,
          rt: apiData.rt,
          rw: apiData.rw,
          latitude: apiData.latitude || 0,
        longitude: apiData.longitude || 0,
          education:apiData.education,
        };
  
        setEditData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const getImagePath = (path) => {
    if (!path) {
      return ''; 
    }
  
    if (typeof path === 'string') {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path; 
      }
     
      return `${process.env.REACT_APP_API_URL}/${path}`;
    }
  
    try {
    
      return URL.createObjectURL(path);
    } catch (error) {
      console.error('Error creating object URL:', error);
      return ''; 
    }
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    handleFormChange('gender', value);
  };
  
  const handlePersonPhotoChange = (e) => {
    const file = e.target.files[0];
    setPersonFile(file); 
    setEditData({ ...editData, person_photo_if_gp: file }); 
  };

  const handleIdCardPhotoChange = (e) => {
    const file = e.target.files[0];
    setIdFile(file); 
    setEditData({ ...editData, id_card_photo_if_gp: file }); 
  };

  const handleDateChange = (newValue, field) => {
    let formattedValue;
  
    if (field === 'visit_date') {
      formattedValue = format(newValue, 'yyyy-MM-dd HH:mm:ss');
    } else if (field === 'date_of_birth') {
      formattedValue = format(new Date(newValue.setHours(0, 0, 0, 0)), 'yyyy-MM-dd HH:mm:ss');
    }
  
    setEditData({ ...editData, [field]: formattedValue });
  };
  
  const handleFormEdit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
  
      for (const key in editData) {
        const value = editData[key];
  
        if (value !== undefined && value !== null) {
          if (key === 'visit_date' || key === 'date_of_birth') {
            const formattedDate = format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
            formData.append(key, formattedDate);
          } else if (key === 'count_equipment_given_if_gp' || key === 'type_equipment_given_if_gp') {
            formData.append(key, value.toString());
          } else if (key === 'person_photo_if_gp' || key === 'id_card_photo_if_gp') {
          
            if (value instanceof File) {
              formData.append(key, value, value.name);
            } else {
            
              formData.append(key, value);
            }
          } else {
            formData.append(key, value);
          }
        }
      }
  
      const confirmed = window.confirm('Data will be edited. Are you sure the data is exactly right?');
  
      if (confirmed) {
        const response = await topaz.put(`${process.env.REACT_APP_API_URL}/api/general-election-voters`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        window.alert('Data has been edited successfully.');
        console.log(response.data);
        navigate('/data/voters');
      } else {
        window.alert('Data editing cancelled.');
      }
    } catch (error) {
      console.error('Error editing data:', error);
      window.alert(`Failed to edit data: ${error.message}`);
    }
  };
  

  
  
  
  
  
  

  const handleFormChange = (field, value) => {

  if (field === 'type_equipment_given_if_gp') {
    setEditData({ ...editData, [field]: value });
  } else if (field === 'count_equipment_given_if_gp') {
    setEditData({ ...editData, [field]: parseInt(value, 10) });
  } else {
    setEditData({ ...editData, [field]: value });
  }
};

const handleEULAChange = (e) => {
  const newValue = e.target.value === '1';
  
  
  if (!editData.installation_willingness_if_gp && newValue) {
    setEditData({
      ...editData,
      installation_willingness_if_gp: '1',
      person_photo_if_gp: null,
      id_card_photo_if_gp: null,
      type_equipment_given_if_gp: null,
      count_equipment_given_if_gp: null,
    });
  } else {
    
    setEditData({ ...editData, installation_willingness_if_gp: newValue ? '1' : '0' });
  }

  setAcceptEULA(newValue);
};
  

  const mapRef = useRef();
  const map = useRef(null);
  const marker = useRef(null);

  useEffect(() => {
    const { latitude, longitude } = editData;
  
 
    const isValidLatLng = !isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude));
  
    if (!map.current && isValidLatLng) {
      map.current = L.map(mapRef.current).setView([parseFloat(latitude), parseFloat(longitude)], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map.current);
  
      marker.current = L.marker([parseFloat(latitude), parseFloat(longitude)]).addTo(map.current);
  
      map.current.on('click', (e) => {
        const { lat, lng } = e.latlng;
        setMapCoordinates({ lat, lng });
  
    
        setEditData((prevEditData) => ({ ...prevEditData, latitude: lat, longitude: lng }));
  
     
        if (marker.current) {
          marker.current.setLatLng([lat, lng]);
        } else {  
          marker.current = L.marker([lat, lng]).addTo(map.current);
        }
      });
    }
  }, [editData.latitude, editData.longitude]);
  
  
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [kabupatenList, setKabupatenList] = useState([]);
  const [selectedKabupaten, setSelectedKabupaten] = useState(null);

  const [kecamatanList, setKecamatanList] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState(null);

  const [kelurahanList, setKelurahanList] = useState([]);
  const [selectedKelurahan, setSelectedKelurahan] = useState(null);
  
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/province/all`);
        if (response.data.status === 'success') {
          setProvinces(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };
  
    fetchProvinces();
  }, []);
  
  
  
  useEffect(() => {
    if (selectedProvince) {
      const fetchKabupaten = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten/name/${selectedProvince}`);
          if (response.data.status === 'success') {
            setKabupatenList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kabupaten:', error);
        }
      };
  
      fetchKabupaten();
    }
  }, [selectedProvince]);
  
  useEffect(() => {
    if (selectedKabupaten) {
      const fetchKecamatan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kecamatan/name/${selectedKabupaten}`);
          if (response.data.status === 'success') {
            setKecamatanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kecamatan:', error);
        }
      };
  
      fetchKecamatan();
    }
  }, [selectedKabupaten]);

  useEffect(() => {
    if (selectedKecamatan) {
      const fetchKelurahan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kelurahan/name/${selectedKecamatan}`);
          if (response.data.status === 'success') {
            setKelurahanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kabupaten:', error);
        }
      };
  
      fetchKelurahan();
    }
  }, [selectedKecamatan]);

  useEffect(() => {
    if (editData.province) {
      setSelectedProvince(editData.province);
    }
  }, [editData.province]);
  
  useEffect(() => {
    if (editData.kabupaten) {
      setSelectedKabupaten(editData.kabupaten);
    }
  }, [editData.kabupaten]);
  
  useEffect(() => {
    if (editData.kecamatan) {
      setSelectedKecamatan(editData.kecamatan);
    }
  }, [editData.kecamatan]);

  useEffect(() => {
    if (editData.kelurahan) {
      setSelectedKelurahan(editData.kelurahan);
    }
  }, [editData.kelurahan]);
  
  const [idCardValidationResult, setIdCardValidationResult] = useState(null);
  const validateIdCardNumber = async (idCardNumber) => {
    try {
      const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/validate-ktp/${idCardNumber}`);
      return response.data;
    } catch (error) {
      console.error('Error validating ID card number:', error);
      return { status: 'error', message: 'Unable to validate ID card number' };
    }
  };
  
  const handleIdCardNumberChange = async (e) => {
    const idCardNumber = e.target.value;
    setEditData({ ...editData, id_card_number: idCardNumber });

    if (idCardNumber.length > 0) {
      console.log('Checking API for ID card validation...');
      const validationResponse = await validateIdCardNumber(idCardNumber);

      console.log('Validation response:', validationResponse);

      if (validationResponse.status === 'error') {
        setIdCardValidationResult({ status: 'error', message: 'Nomor KTP sudah digunakan.' });
      } else {
        setIdCardValidationResult(null);
      }
    } else {
      setIdCardValidationResult(null);
    }
  };
  
  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/voters');
    }
  };

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
    <Grid item xs={12} sm={10} md={8}> 
      <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
        <CardContent>
            <form onSubmit={handleFormEdit}>
              <Grid container spacing={3} direction="column">
               
              <Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      renderInput={(params) => <TextField {...params} />}
      label="Tanggal Kunjungan"
      value={editData.visit_date ? new Date(editData.visit_date) : null}
      onChange={(newValue) => handleDateChange(newValue, 'visit_date')}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>

                <Grid item>
        <div ref={mapRef} style={{ height: '480px', width: '800px', margin: '0 auto' }} />
      </Grid>
      <Grid item>
                    <Grid container spacing={3}>
                        <Grid item xs={true}>
                            <TextField
                         
                                placeholder="Place Google Maps URL Here"
                                label='Use Google Maps URL (OPTIONAL)'
                                fullWidth />
                        </Grid>
                        <Grid item>
                            <Button type='button' variant='contained' sx={{ height: '56px', paddingLeft: '16px', paddingRight: '16px' }}>Get Location</Button>
                        </Grid>
                    </Grid>
                </Grid>
      <Grid item container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <TextField
            onChange={handleFormChange}
            value={editData.latitude}
            name="lat"
          
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleFormChange}
            value={editData.longitude}
            name="lng"
           
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
                
                
                
                <Grid item>
                  <Typography variant="h6" color="primary">Informasi Pribadi</Typography>
                </Grid>
              
                <Grid item>
                  <TextField
                   onChange={(e) => handleFormChange('full_name', e.target.value)}
                   value={editData ? editData.full_name : ''}
                   name="full_name"
                   label="Nama Lengkap"
                   fullWidth
                  />
                </Grid>
            
                <Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      label="Tanggal Lahir"
      value={editData.date_of_birth ? new Date(editData.date_of_birth) : null}
      onChange={(newValue) => handleDateChange(newValue, 'date_of_birth')}
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
</Grid>
      <Grid item xs={12}>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={editData.gender} 
                  onChange={handleRadioChange}
                  row
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1" color="textSecondary">Jenis Kelamin :</Typography>
                    </Grid>
                    
                    <Grid item>
                      <FormControlLabel value="Laki-laki" control={<Radio />} label="Laki-laki" />
                    </Grid>
                    <Grid item>
                      <FormControlLabel value="Perempuan" control={<Radio />} label="Perempuan" />
                    </Grid>
                   
                  </Grid>
                </RadioGroup>
              </Grid>
              <Grid item>
  <TextField
    onChange={(e) => {
      handleFormChange('id_card_number', e.target.value);
      handleIdCardNumberChange(e); 
    }}
    value={editData ? editData.id_card_number : ''}
    name="id_card_number"
    label="Nomor KTP"
    fullWidth
    type="number"
    error={idCardValidationResult && idCardValidationResult.status === 'error'}
    helperText={idCardValidationResult && idCardValidationResult.message}
  />
</Grid>

                <Grid item>
                <TextField
    onChange={(e) => handleFormChange('family_card_number', e.target.value)}
    value={editData ? editData.family_card_number : ''}
    name="family_card_number"
    label="Nomor KK"
    fullWidth
    type="number" 
  />
                </Grid>
                <Grid item>
  <Select
    value={editData.religion || ''}
    onChange={(e) => handleFormChange('religion', e.target.value)}
    displayEmpty
    fullWidth
    placeholder="Pilih Agama"
  >
    <MenuItem value="" disabled>
      Pilih Agama
    </MenuItem>
    <MenuItem value="Islam">Islam</MenuItem>
        <MenuItem value="Kristen">Kristen</MenuItem>
        <MenuItem value="Budha">Budha</MenuItem>
        <MenuItem value="Hindu">Hindu</MenuItem>
        <MenuItem value="Lainnya">Lainnya</MenuItem>
        <MenuItem value="Tidak Menjawab">Tidak Menjawab</MenuItem>
  </Select>
</Grid>

                <Grid item>
                <TextField
                    onChange={(e) => handleFormChange('occupation', e.target.value)}
                    value={editData ? editData.occupation : ''}
                    name="occupation"
                    label="Pekerjaan"
                    fullWidth
                  />
                </Grid>
                <Grid item>
      <Select
        value={editData.education || ''}
        onChange={(e) => handleFormChange('education', e.target.value)}
        displayEmpty
        fullWidth
        placeholder="Pendidikan Terakhir"
      >
        <MenuItem value="" disabled>
          Pendidikan Terakhir
        </MenuItem>
       
        <MenuItem value="SD">SD</MenuItem>
        <MenuItem value="SMP">SMP</MenuItem>
        <MenuItem value="SMA">SMA</MenuItem>
        <MenuItem value="PERGURUAN TINGGI">PERGURUAN TINGGI</MenuItem>
        <MenuItem value="TIDAK BERSEKOLAH">TIDAK BERSEKOLAH</MenuItem>
      </Select>
    </Grid>
      
      <Grid item>
                  <Typography variant="h6" color="primary">Informasi Kontak</Typography>
                </Grid>
                <Grid item >
                  
                  
                <TextField
    onChange={(e) => handleFormChange('phone_number', e.target.value)}
    value={editData ? editData.phone_number : ''}
    name="phone_number"
    label="Nomor Telepon"
    fullWidth
    type="number"  
  />
                  
                </Grid>
                
                <Grid item>
      <TextField
         onChange={(e) => handleFormChange('email', e.target.value)}
         value={editData ? editData.email : ''}
         name="email"
         label="E-Mail"
         fullWidth
      />
    </Grid>

    

    

    
    <Grid item>
    <Autocomplete
  options={provinces}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedProvince(newValue);
   
    setSelectedKabupaten(null);
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, province: newValue ? newValue.name : null, kabupaten: null, kecamatan: null, kelurahan: null }));
  }}
  value={provinces.find((province) => province.name === selectedProvince) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Provinsi"
      placeholder="Pilih Provinsi"
    />
  )}
/>

</Grid>
   


    <Grid item>
    <Autocomplete
  options={kabupatenList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKabupaten(newValue);
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, kabupaten: newValue ? newValue.name : null, kecamatan: null, kelurahan: null }));
  }}
  value={kabupatenList.find((kabupaten) => kabupaten.name === selectedKabupaten) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kabupaten"
      placeholder="Pilih Kabupaten"
      disabled={!selectedProvince}
    />
  )}
/>

</Grid>

<Grid item>
<Autocomplete
  options={kecamatanList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKecamatan(newValue);
  
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, kecamatan: newValue ? newValue.name : null, kelurahan: null }));
  }}
  value={kecamatanList.find((kecamatan) => kecamatan.name === selectedKecamatan) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kecamatan"
      placeholder="Pilih Kecamatan"
      disabled={!selectedKabupaten}
    />
  )}
/>

</Grid>
   
<Grid item>
<Autocomplete
  options={kelurahanList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKelurahan(newValue);
    setEditData((prevData) => ({ ...prevData, kelurahan: newValue ? newValue.name : null }));
  }}
  value={kelurahanList.find((kelurahan) => kelurahan.name === selectedKelurahan) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kelurahan"
      placeholder="Pilih Kelurahan"
      disabled={!selectedKecamatan}
    />
  )}
/>

</Grid>
    <Grid item>
      <TextField
       onChange={(e) => handleFormChange('dusun', e.target.value)}
       value={editData ? editData.dusun : ''}
       name="dusun"
       label="Dusun"
       fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6}>
  <Grid container spacing={3} alignItems="center">
    <Grid item xs={6}>
      
    <TextField
    onChange={(e) => handleFormChange('rt', e.target.value)}
    value={editData ? editData.rt : ''}
    name="rt"
    label="RT"
    fullWidth
    type="number"  
  />
    </Grid>
    <Grid item xs={6}>
     
    <TextField
    onChange={(e) => handleFormChange('rw', e.target.value)}
    value={editData ? editData.rw : ''}
    name="rw"
    label="RW"
    fullWidth
    type="number"  
  />
    </Grid>
  </Grid>
</Grid>
<Grid item>
             
              <Typography variant="h6" color="primary">Pilih Pasangan</Typography>
            </Grid>

    <Grid item xs={12}>
    <RadioGroup
  aria-label="support_direction"
  name="support_direction"
  value={editData.support_direction}
  onChange={handleSupportDirectionChange}
  row
>
  <Grid container spacing={2} alignItems="center">
    <Grid item>
      <FormControlLabel
        value="1"
        control={<Radio />}
        label={
          <div>
            <img src={C1} alt="Anies Rasyid Baswedan" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C4} alt="Abdul Muhaimin Iskandar" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    
    <Grid item>
      <FormControlLabel
        value="2"
        control={<Radio />}
        label={
          <div>
            <img src={C3} alt="Prabowo Subianto Djojohadikusumo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C6} alt="Gibran Rakabuming Raka" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    <Grid item>
      <FormControlLabel
        value="3"
        control={<Radio />}
        label={
          <div>
            <img src={C2} alt="Ganjar Pranowo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C5} alt="Mohammad Mahfud Mahmodin" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    <Grid item>
      <FormControlLabel
        value="0"
        control={<Radio />}
        label="Tidak Memilih"
      />
    </Grid>
  </Grid>
</RadioGroup>

      </Grid>

      {editData.support_direction === '0' && (
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            Apakah bersedia menerima Alat Peraga Kampanye (APK)?
          </Typography>
          <RadioGroup
            aria-label="accept_eula"
            name="accept_eula"
            value={acceptEULA ? acceptEULA.toString() : ''}
            onChange={handleEULAChange}
            row
          >
            <FormControlLabel value="1" control={<Radio />} label="Ya" />
            <FormControlLabel value="0" control={<Radio />} label="Tidak" />
          </RadioGroup>
        </Grid>
      )}
    

    {acceptEULA == "1" && (
  <>
    <Grid item>
      <Typography variant="h6" color="primary">Informasi Tambahan</Typography>
    </Grid>
                
                
     
      
    
    <Grid item>
                    <div style={{
                      position: 'relative',
                      width: '50%',
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      padding: '16.5px 14px'
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: '-11px',
                        left: '-12px',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        background: 'white',
                        padding: '8px',
                        color: '#9a9a9a',
                        transform: 'translate(14px, -9px) scale(0.75)'
                      }}>Foto Wajah</div>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <>
                            <input
                              id='person-photo-upload'
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={handlePersonPhotoChange}
                            />
                            <label htmlFor='person-photo-upload'>
                              <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                            </label>
                          </>
                        }
                      >
                        <Avatar src={getImagePath(editData.person_photo_if_gp)} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>
  Foto Wajah
</Avatar></Badge>
                    </div>
                  </Grid>

                  <Grid item>
                    <div style={{
                      position: 'relative',
                      width: '50%',
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      padding: '16.5px 14px'
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: '-11px',
                        left: '-12px',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        background: 'white',
                        padding: '8px',
                        color: '#9a9a9a',
                        transform: 'translate(14px, -9px) scale(0.75)'
                      }}>Foto KTP</div>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <>
                            <input
                              id='id-card-photo-upload'
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={handleIdCardPhotoChange}
                            />
                            <label htmlFor='id-card-photo-upload'>
                              <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                            </label>
                          </>
                        }
                      >
                        <Avatar src={getImagePath(editData.id_card_photo_if_gp)} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>
  Foto KTP
</Avatar></Badge>
                    </div>
                  </Grid>

         
                  <Grid item>
  <Select
    value={editData.type_equipment_given_if_gp || ''}
    onChange={(e) => handleFormChange('type_equipment_given_if_gp', e.target.value)}
    displayEmpty
    fullWidth
    placeholder="Tipe APK"
  >
    <MenuItem value="" disabled>
      Tipe APK
    </MenuItem>
    <MenuItem value="Baliho Bambu 300x400">Baliho Bambu 300x400</MenuItem>
        <MenuItem value="Billboard">Billboard</MenuItem>
        <MenuItem value="Umbul-Umbul 70-300">Umbul-Umbul 70-300</MenuItem>
        <MenuItem value="Spanduk 300x100">Spanduk 300x100</MenuItem>
        <MenuItem value="Spanduk 700x100">Spanduk 700x100</MenuItem>
        <MenuItem value="Spanduk Warung">Spanduk Warung</MenuItem>
  </Select>
</Grid>

                  <Grid item>
  <TextField
    onChange={(e) => handleFormChange('count_equipment_given_if_gp', e.target.value)}
    value={editData ? editData.count_equipment_given_if_gp : ''}
    name="count_equipment_given_if_gp"
    label="Jumlah APK"
    fullWidth
    type="number" 
  />
</Grid>
               </>
                
                )
                }

<Grid item>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
    <Button variant="contained" color="error" onClick={handleCancel}>
                  Batal
                </Button>
    <Button variant="contained" type="submit">
        Ubah
      </Button>
      
      </Box>
    </Grid>
      
    </Grid>
              
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormEdit;