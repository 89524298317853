import { People, PersonPinCircle, TravelExplore } from '@mui/icons-material'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

const CardCount = ({ type, total }) => {
    return (
        <Card sx={{ boxShadow: 'none', borderRadius: '8px' }}>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <Grid container justifyContent='space-between' sx={{ marginBottom: '8px' }}>
                    <Grid item>
                        <Typography sx={{ fontSize: '14px', opacity: '0.7', fontWeight: '500' }}>
                            {type === 'target' ? 'Jumlah Target Total' : type === 'voters' ? 'Jumlah Penduduk Terdata' : type === 'kabupaten' ? 'Jumlah Kabupaten Terjangkau' : 'Jumlah Kecamatan Terjangkau'}
                        </Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>{total}</Typography>
                    </Grid>
                    <Grid item>
                        {type === 'target' && <PersonPinCircle sx={{ color: 'primary.main', fontSize: '48px' }} />}
                        {type === 'voters' && <People sx={{ color: 'primary.main', fontSize: '48px' }} />}
                        {type === 'kecamatan' && <TravelExplore sx={{ color: 'primary.main', fontSize: '48px' }} />}
                        {type === 'kabupaten' && <TravelExplore sx={{ color: 'primary.main', fontSize: '48px' }} />}
                    </Grid>
                </Grid>
                <span style={{ fontSize: '16px', fontWeight: '400', opacity: '0.7' }}><span style={{ color: 'green', fontWeight: '500' }}>+0%</span> sejak bulan lalu</span>
            </CardContent>
        </Card>
    )
}

export default CardCount