import { Avatar, IconButton, Card, Divider, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const UtilDetailPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const supportMapping = {
        0: 'Tidak Memilih',
        1: 'Anies & Muhaimin',
        3: 'Ganjar & Mahfud',
        2: 'Prabowo & Gibran',
    };
    const handleGoBack = () => {
        navigate('/data/inspection');
    };
   
    console.log(state)
    return (
        <Card>
            <Grid container>
                <Grid item xs={3} sx={{ background: '#1976d2', padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
              
  <IconButton style={{ backgroundColor: 'red' }} onClick={handleGoBack}>
    <ArrowBackIcon style={{ color: 'white' }} />
  </IconButton>
  
                    
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '16px', color: '#e3f2fd' }}>PIC Pemasangan</Typography>
                    <Typography>{state.pic_installation}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>PIC Pemantauan</Typography>
                    <Typography>{state.pic_inspector}</Typography>
                    
                </Grid>
                <Divider flexItem />
                <Grid item xs={true} sx={{ padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
                    
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Tanggal Kunjungan</Typography>
                    <Typography>{new Date(state.visit_date).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Tanggal Pengecekan</Typography>
                    <Typography>{new Date(state.inspect_date).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kondisi APK</Typography>
                    <Typography>{state.apk_condition}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Mendukung</Typography>
                    <Typography>{supportMapping[state.support_sign]}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Menolak</Typography>
                    <Typography>{state.disapproval_sign}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Foto APK</Typography>
{state.photo ? (
  <img src={`${apiUrl}/${state.photo}`} alt="Foto Orang" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '8px' }} />
) : (
  <Typography>Tidak Tersedia</Typography>
)}
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Keterangan</Typography>
                    <Typography>{state.description}</Typography>
                    
                </Grid>
            </Grid>
        </Card>
    )
}

export default UtilDetailPage