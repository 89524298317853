import axios from 'axios';
import { getAccessToken } from './Auth';

const topaz = axios.create();

topaz.interceptors.request.use(
    (config) => {
        const accessToken = getAccessToken();

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            console.error('Access token is missing or expired.');
            localStorage.removeItem('user');
        }

        return config;
    },
    error => Promise.reject(error)
);

export default topaz;