import topaz from '../components/TokenPass';
import { Card, CardContent, Grid, Box } from '@mui/material'
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import React, { useState, useEffect }from 'react'

const DashboardCardB = () => {
    const [chartData, setChartData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const COLORS = ['#3f51b5', '#e91e63', '#9c27b0', '#009688']; 

    useEffect(() => {
        topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/page/1/max/1000`)
          .then(response => {
            const chartData = response.data.data;
      
            const supportDirectionData = chartData.reduce((acc, voter) => {
              let support = '';
      
              switch (voter.support_direction) {
                case '1':
                  support = 'Anies & Muhaimin';
                  break;
                case '2':
                  support = 'Ganjar & Mahfud';
                  break;
                case '3':
                  support = 'Prabowo & Gibran';
                  break;
                default:
                  support = 'Tidak Memilih';
              }
      
              acc[support] = (acc[support] || 0) + 1;
              return acc;
            }, {});
      
            const formattedChartData = Object.keys(supportDirectionData).map(label => ({
              name: label,
              value: supportDirectionData[label]
            }));
      
            setChartData(formattedChartData);
            setTotalItems(response.data.totalItems || 0);
          })
          .catch(error => {
            console.error('Error fetching chart data:', error);
          });
      }, []);

    return (
       <Card sx={{ width: '100%' }}>
      <CardContent>
        <div style={{ fontSize: '18px', fontWeight: '600', fontFamily: '"Public Sans", sans-serif', marginBottom: '16px' }}>
          Diagram Vote
        </div>
        <Grid container sx={{ fontSize: '14px' }}>
          <PieChart width={400} height={400} align="center">
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend align="left" iconSize={12} wrapperStyle={{ paddingBottom: '10px' }} />
          </PieChart>
          <p style={{ marginTop: '20px' }}>Total Data : {totalItems}</p>
        </Grid>
      </CardContent>
    </Card>
    )
}

export default DashboardCardB