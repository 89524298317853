import firebase from '../firebase';

const getUserKey = () => {
    let user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

const handleGoogleSignIn = async () => {
    try {
        const provider = new firebase.auth.GoogleAuthProvider();
        const result = await firebase.auth().signInWithPopup(provider);
        localStorage.setItem('user', JSON.stringify(result.user));
        return true;
    } catch (error) {
        console.error('Google Sign-In Error:', error.message);
        return false;
    }
};

const isLoggedIn = () => {
    return getUserKey() !== null;
};

const getUserInfo = () => {
    return getUserKey();
};

const getAccessToken = () => {
    const userData = getUserKey();
    if (userData && userData.stsTokenManager) {
        return userData.stsTokenManager.accessToken || null;
    }
    return null;
};

export { handleGoogleSignIn, isLoggedIn, getUserInfo, getAccessToken };
