import React, { useState } from 'react'
// import { signOut } from 'firebase/auth'
// import { auth } from '../firebase'
import { getUserInfo } from '../components/Auth'
import { Outlet, useNavigate } from "react-router-dom"
import { AccountBox, Analytics, AppRegistration, Apps, Ballot, Devices, Feed, Home, LocalAtm, Logout, Mail, MeetingRoom, Menu as MenuIcon, People, Router, Settings, Source, TableChart } from '@mui/icons-material'
import { AppBar, Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import logo from '../assets/img/Logoset_01.png'
import avatarLogo from '../assets/img/avatar.jpg';
const sidebar = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: <Apps />
    },
    {
        name: 'Analytics',
        path: '/analytics',
        icon: <Analytics />
    },
    {
        name: 'Data',
        path: '/data',
        icon: <Source />
    },
    {
        name: 'Logout',
        path: '/logout',
        icon: <Logout />
    },
]

const Layout = ({ children }) => {
    const navigate = useNavigate()
    const [user, setUser] = useState(getUserInfo());
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <AppBar position='static' sx={{ display: { md: 'none' } }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={logo} alt='logo pusaka eagle eye' style={{ height: '48px' }} />
                </Toolbar>
                <Menu
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {sidebar.map(x =>
                        <MenuItem key={x.name} onClick={() => navigate(x.path)} sx={{ width: '230px' }}>{x.name}</MenuItem>
                    )}
                </Menu>
            </AppBar>
            <Box sx={{ display: 'flex' }}>
                <Drawer variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        width: { xs: '0', md: '88px', lg: '208px' },
                    }}>
                    <Avatar
    src={user?.photoURL || avatarLogo}
    alt="User Avatar"
    sx={{
        marginX: 'auto',
        marginY: '24px',
        cursor: 'pointer',
    }}
/>

                    <Divider />
                    <List>
                        {sidebar.map(x =>
                            <ListItem key={x.name} disablePadding sx={{ display: 'block' }}>
                                <Tooltip title={x.name} placement='right-end'>
                                    <ListItemButton onClick={() => navigate(x.path)}>
                                        <ListItemIcon sx={{ margin: 'auto', justifyContent: 'center' }}>
                                            {x.icon}
                                        </ListItemIcon>
                                        <ListItemText sx={{ width: '120px', display: { xs: 'none', lg: 'block' } }}>
                                            {x.name}
                                        </ListItemText>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        )}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, padding: { xs: '8px 0', md: '24px', lg: '24px' }, width: '100%' }}
                >
                    <Outlet />
                </Box>
            </Box >
        </>
    )
}

export default Layout