import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import MapWithCitySelection from '../components/MapComponent';
import topaz from '../components/TokenPass';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const HomePage = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [votersData, setVotersData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const COLORS = ['#3f51b5', '#e91e63', '#9c27b0', '#009688']; 


  useEffect(() => {
    topaz.get(`${process.env.REACT_APP_API_URL}/api/target/page/1/max/1000`)
      .then(response => {
        setLocations(response.data.data);
        setSelectedLocation(response.data.data[0]);
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }, []);
  
  useEffect(() => {
    topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/recent`)
      .then(response => {
        setVotersData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching voters data:', error);
      });
  }, []);
  
  useEffect(() => {
    topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/page/1/max/1000`)
      .then(response => {
        const chartData = response.data.data;
  
        const supportDirectionData = chartData.reduce((acc, voter) => {
          let support = '';
  
          switch (voter.support_direction) {
            case '1':
              support = 'Anies Rasyid Baswedan & Abdul Muhaimin Iskandar';
              break;
            case '2':
              support = 'Ganjar Pranowo & Mohammad Mahfud Mahmodin';
              break;
            case '3':
              support = 'Prabowo Subianto Djojohadikusumo & Gibran Rakabuming Raka';
              break;
            default:
              support = 'No Selection';
          }
  
          acc[support] = (acc[support] || 0) + 1;
          return acc;
        }, {});
  
        const formattedChartData = Object.keys(supportDirectionData).map(label => ({
          name: label,
          value: supportDirectionData[label]
        }));
  
        setChartData(formattedChartData);
        setTotalItems(response.data.totalItems || 0);
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
      });
  }, []);
  

  const handleLocationChange = (event, value) => {
    const location = locations.find(loc => loc.kecamatan.toLowerCase() === value.toLowerCase());
    if (location) {
      setSelectedLocation(location);
    }
  };

  

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Card style={{ margin: '20px', width: '90%', marginTop: '90px'}}>
          <CardContent>
            <h1>Select Location</h1>
            <Autocomplete
              options={locations.map(option => option.kecamatan)}
              renderInput={(params) => (
                <TextField {...params} label="Select Location" variant="outlined" />
              )}
              onChange={handleLocationChange}
            />
            <Box mt={2} border={1} borderColor="grey.300" borderRadius={4}>
              <MapWithCitySelection selectedLocation={selectedLocation} locations={locations} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{ margin: '20px', width: '90%', marginTop: '90px' }}>
          <CardContent>
            <h1>Recent Election Voters</h1>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {votersData.map((voter) => (
                    <TableRow key={voter.id}>
                      <TableCell>{voter.full_name}</TableCell>
                      <TableCell>{voter.id_card_number}</TableCell>
                      {/* Add more TableCell components for other data */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <h1>Support Direction Distribution</h1>
              <PieChart width={400} height={400}>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
              <p>Total Items: {totalItems}</p> {/* Ensure totalItems is displayed here */}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomePage;
