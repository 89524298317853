import React, { useEffect, useState } from 'react'

import { CircleMarker, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button, Card, CardContent, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getKabupaten } from '../../utils/ApiList';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Maps = ({ data }) => {
    const navigate = useNavigate()
    const [selected, setSelected] = useState()
    const [kabupaten, setKabupaten] = useState(0)
    const [arrKabupaten, setArrKabupaten] = useState([])
    const handleClear = () => setSelected()
    const handleDetailMap = (e) => navigate("/target-detail", { state: selected })
    const handleChangeKabupaten = (e) => setKabupaten(e.target.value)

    useEffect(() => {
        getKabupaten('')
            .then(response => setArrKabupaten(response.data.data))
    }, [])
    return (
        <Card sx={{ position: 'relative' }}>
            <CardContent>
                <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                    <Grid item xs={true}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', opacity: '0.7' }}>Peta Target Suara</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={kabupaten}
                            onChange={handleChangeKabupaten}
                            fullWidth
                            select>
                            <MenuItem value={0}>Semua</MenuItem>
                            {arrKabupaten.map(x =>
                                <MenuItem key={x.id} value={x.name}>{x.name}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </Grid>

            </CardContent>
            <MapContainer center={[-8.674330665629418, 117.3007753818085]} zoom={8} style={{ width: '100%', height: '100%', minHeight: '427px', zIndex: 0 }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {data.map(x =>
                    <CircleMarker
                        key={x.id}
                        center={[x.latitude, x.longitude]}
                        radius={20 * (x.target / 50000)}
                        fillOpacity={0.5}
                        eventHandlers={{
                            click: (e) => {
                                setSelected(x);
                            },
                        }}
                        stroke={false}>
                        <Tooltip>
                            <div style={{ fontWeight: 'bold' }}>{x.kecamatan}</div>
                            <div>Target: {x.target}</div>
                            <div>Potensi: {x.potensi}</div>
                        </Tooltip>
                    </CircleMarker>
                )}
            </MapContainer>
            {selected &&
                <CardContent sx={{ position: 'absolute', zIndex: '10', paddingTop: '35px', bottom: '0', background: 'linear-gradient(to bottom, rgba(255,0,0,0) ,30px, #ffffff)', width: 'calc(100% - 32px)', }}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <div style={{ fontSize: '22px' }}>{selected.kecamatan}, KAB. {selected.kabupaten}</div>
                        </Grid>
                        <Grid item><IconButton onClick={handleClear}><Close /></IconButton></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div style={{ fontSize: '14px' }}>Jumlah desa</div>
                            <div style={{ fontSize: '14px' }}>Jumlah TPS</div>
                            <div style={{ fontSize: '14px', marginTop: '16px' }}>Total DPT</div>
                            <div style={{ fontSize: '14px' }}>Potensi</div>
                            <div style={{ fontSize: '14px' }}>Target</div>
                        </Grid>
                        <Grid item xs={true}>
                            <div style={{ fontSize: '14px' }}>: {selected.count_desa}</div>
                            <div style={{ fontSize: '14px' }}>: {selected.count_tps}</div>
                            <div style={{ fontSize: '14px', marginTop: '16px' }}>: {selected.dpt}</div>
                            <div style={{ fontSize: '14px' }}>: {selected.potensi}</div>
                            <div style={{ fontSize: '14px' }}>: {selected.target}</div>
                        </Grid>
                        <Grid item style={{ marginRight: '48px' }}>
                            <div style={{ textAlign: 'center' }}>Kuadran</div>
                            <div style={{ fontSize: '48px', fontWeight: 'bold', textAlign: 'center' }}>{selected.kuadran}</div>
                            <Button variant='outlined' onClick={handleDetailMap}>Lihat Detail</Button>
                        </Grid>
                    </Grid>
                </CardContent >
            }
        </Card>
    )
}

export default Maps