import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, CircularProgress, Radio, RadioGroup, FormControlLabel, Grid, Badge, Avatar, IconButton, Card, CardContent, Typography, Select, MenuItem, TextField, Alert } from '@mui/material';

import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import L from 'leaflet';
import markerIcon from '../../../assets/leaflet/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import { format } from 'date-fns';

import { useSnackbar } from 'notistack';
import { getUserInfo } from '../../../components/Auth';
import { getLocation } from '../../../components/GetLocation';
import topaz from '../../../components/TokenPass';



const FormPemasangan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    pic_name: '',
    pic_phone: '',
    installation_date: '',
    apk_code: '',
    apk_type: '',
    apk_size: '',
    apk_count: '',
    location_type: '',
    location_name: '',
    dusun: '',
    kelurahan: '',
    kecamatan: '',
    kabupaten: '',
    province: '',
    google_maps_link: '',
    created_by: '',
    latitude: '',
    longitude: '',
  });
 console.log(formData);
  
 


 
 const [mapCoordinates, setMapCoordinates] = useState({ lat: '', lng: '' });
  const [formErrors, setFormErrors] = useState({});
  const today = new Date();
  const installation_date = formData.installation_date;
const defaultInstallationtDate = installation_date ? new Date(installation_date) : today;


const [provinces, setProvinces] = useState([]);
const [selectedProvince, setSelectedProvince] = useState('');
const [kabupatenList, setKabupatenList] = useState([]);
const [selectedKabupaten, setSelectedKabupaten] = useState('');

const [kecamatanList, setKecamatanList] = useState([]);
const [selectedKecamatan, setSelectedKecamatan] = useState('');

const [kelurahanList, setKelurahanList] = useState([]);
const [selectedKelurahan, setSelectedKelurahan] = useState('');

useEffect(() => {
  const fetchProvinces = async () => {
    try {
      const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/province/all`);
      if (response.data.status === 'success') {
        setProvinces(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };
  

  fetchProvinces();
}, []);

useEffect(() => {
  if (selectedProvince) {
    setSelectedKabupaten(null);
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    const fetchKabupaten = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten?province_id=${selectedProvince.id}`);
        if (response.data.status === 'success') {
          setKabupatenList(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching kabupaten:', error);
      }
    };

    fetchKabupaten();
  }
}, [selectedProvince]);

useEffect(() => {
  if (selectedKabupaten) {
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    const fetchKecamatan = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kecamatan/kabupaten-id/${selectedKabupaten.id}`);
        if (response.data.status === 'success') {
          setKecamatanList(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching kecamatan:', error);
      }
    };

    fetchKecamatan();
  }
}, [selectedKabupaten]);

useEffect(() => {
  if (selectedKecamatan) {
    setSelectedKelurahan(null);
    const fetchKelurahan = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kelurahan?kecamatan_id=${selectedKecamatan.id}`);
        if (response.data.status === 'success') {
          setKelurahanList(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching Kelurahan:', error);
      }
    };

    fetchKelurahan();
  }
}, [selectedKecamatan]);




const onChange = (value, field) => {
  if (field === 'installation_date') {
    const formattedInstallationDate = format(value, 'yyyy-MM-dd HH:mm:ss');
    setFormData({ ...formData, [field]: formattedInstallationDate });
  
  } else {
    setFormData({ ...formData, [field]: value });
  }
};




 


  const mapRef = useRef();
  let map = null;
  let marker = null;
  const markerRef = useRef();
  const [loadingLocation, setLoadingLocation] = useState(false);

  useEffect(() => {
    if (!map) {
      map = L.map(mapRef.current).setView([-8.633, 116.267], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

      map.on('click', (e) => {
        const { lat, lng } = e.latlng;
        setMapCoordinates({ lat, lng });

        if (markerRef.current) {
    
          markerRef.current.setLatLng([lat, lng]);
        } else {
          
          const icon = L.icon({
            iconUrl: markerIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          });

          markerRef.current = L.marker([lat, lng], { icon }).addTo(map);
        }
      });
    }
  }, [map]);


  const handleGetLocation = async () => {
    try {
      setLoadingLocation(true);
      const url = formData.google_maps_link;
  
      if (!url) {
        enqueueSnackbar('Please enter a Google Maps URL', { variant: 'warning' });
        setLoadingLocation(false); // Stop loading
        return;
      }
  
      const location = await getLocation(url);
  
      setMapCoordinates({
        lat: location.latitude,
        lng: location.longitude,
      });
  
      if (markerRef.current) {
        markerRef.current.setLatLng([location.latitude, location.longitude]);
      } else {
        const icon = L.icon({
          iconUrl: markerIcon,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        });
  
        markerRef.current = L.marker([location.latitude, location.longitude], { icon }).addTo(map);
      }
  
      setFormData({
        ...formData,
        latitude: location.latitude,
        longitude: location.longitude,
      });
  
      enqueueSnackbar('Location obtained successfully', { variant: 'success' });
      setLoadingLocation(false);
    } catch (error) {
      setLoadingLocation(false);
      enqueueSnackbar('Failed to obtain location', { variant: 'error' });
    }
  };
  

  useEffect(() => {
    const user = getUserInfo();

    if (user && user.email) {
      setFormData((prevData) => ({
        ...prevData,
        created_by: user.email, 
      }));
    }
  }, []); 

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      installation_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      province: selectedProvince ? selectedProvince.name : '',
  kabupaten: selectedKabupaten ? selectedKabupaten.name : '',
  kecamatan: selectedKecamatan ? selectedKecamatan.name : '',
  kelurahan: selectedKelurahan ? selectedKelurahan.name : '',
      latitude: mapCoordinates.lat,
      longitude: mapCoordinates.lng,
    }));
  }, [mapCoordinates.lat, mapCoordinates.lng, selectedProvince, selectedKabupaten, selectedKecamatan, selectedKelurahan]);
  
  
  



  

  const handleFormChange = (e) => {
  if (e.target.name === 'pic_phone') {
 
    const numericValue = e.target.value.replace(/\D/g, '');
    setFormData({ ...formData, [e.target.name]: numericValue });
  } else if (e.target.name === 'apk_count') {

    const numericValue = e.target.value.replace(/\D/g, ''); 
    setFormData({ ...formData, [e.target.name]: numericValue });
  } else if (e.target.name === 'lat' || e.target.name === 'lng') {
    const newCoordinates = {
      lat: e.target.name === 'lat' ? e.target.value : formData.lat,
      lng: e.target.name === 'lng' ? e.target.value : formData.lng,
    };

    setFormData((prevData) => ({
      ...prevData,
      ...newCoordinates,
    }));
  } else {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
};

  
  
  

  const validateForm = () => {
    const errors = {};

    if (!formData.pic_name || !formData.longitude || !formData.latitude || !formData.pic_phone || !formData.installation_date || !formData.apk_code || !formData.apk_type || !formData.apk_count || !formData.location_type || !formData.location_name || !formData.dusun || !formData.kelurahan || !formData.kecamatan || !formData.kabupaten || !formData.province) {
        errors.form = 'Please fill in all the required fields';

        
        enqueueSnackbar(errors.form, { variant: 'error' });
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
};

  
  
  
const handleFormSubmit = async (e) => {
    e.preventDefault();
  
   
    const isConfirmed = window.confirm('Are you sure you want to submit the form?');
  
    if (isConfirmed && validateForm()) {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/api/campaign-tool-installation`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
  
        
        const response = await topaz(config);
  
        if (response.data.status === 'success') {
          enqueueSnackbar('Form submitted successfully!', { variant: 'success' });
         
          navigate('/data/installation');
        } else {
          enqueueSnackbar('Failed to submit the form. Please try again.', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        enqueueSnackbar('An error occurred while submitting the form. Please try again.', { variant: 'error' });
      }
    }
  };
  
  
  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/installation');
    }
  };
  
  

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
    <Grid item xs={12} sm={10} md={8}> 
      <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
        <CardContent>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={3} direction="column">
               
             
                
                <Grid item>
                  <div ref={mapRef} style={{ height: '480px', width: '800px', margin: '0 auto' }} />
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item xs={true}>
                        <TextField
            placeholder="Place Google Maps URL Here"
            label="Use Google Maps URL (OPTIONAL)"
            fullWidth
            name="google_maps_link"
            value={formData.google_maps_link}
            onChange={(e) => handleFormChange(e)}
          />
                        </Grid>
                        <Grid item>
                        <Button
          type="button"
          variant="contained"
          sx={{ height: '56px', paddingLeft: '16px', paddingRight: '16px' }}
          onClick={handleGetLocation}
          disabled={loadingLocation} 
        >
          {loadingLocation ? <CircularProgress size={24} /> : 'Get Location'}
        </Button></Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={3} alignItems="center">
                  <Grid item xs={6}>
                  <TextField
    onChange={handleFormChange}
    value={mapCoordinates.lat}
    name="lat"
    label="Latitude"
    fullWidth
    disabled
  />
                  </Grid>
                  <Grid item xs={6}>
                  <TextField
    onChange={handleFormChange}
    value={mapCoordinates.lng}
    name="lng"
    label="Longitude"
    fullWidth
    disabled
  />
                  </Grid>
                  
                </Grid>
               
                <Grid item>
                  <Typography variant="h6" color="primary">Info Pribadi</Typography>
                </Grid>
              
                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.pic_name}
                    name="pic_name"
                    label="Nama PIC"
                    fullWidth
                  />
                </Grid>
                <Grid item >
                  
                  
                  <TextField
                    onChange={handleFormChange}
                    value={formData.pic_phone}
                    name="pic_phone"
                    type="number" 
                    label="Nomor PIC"
                    fullWidth
                  />
                
              </Grid>
              <Grid item>
                  <Typography variant="h6" color="primary">Pemasangan APK</Typography>
                </Grid>
                <Grid item >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DateTimePicker
    label="Tanggal Pemasangan"
    value={formData.installation_date ? new Date(formData.installation_date) : null}
    onChange={(newValue) => onChange(newValue, 'installation_date')}
    renderInput={(params) => <TextField {...params} />}
    ampm={false} 
  />
</LocalizationProvider>
              </Grid>
              
      <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.apk_code}
                    name="apk_code"
                   
                    label="Kode APK"
                    fullWidth
                  />
                </Grid>
                <Grid item>
      <Select
        value={formData.apk_type || ''}
        onChange={(e) => onChange(e.target.value, 'apk_type')}
        displayEmpty
        fullWidth
        placeholder="Tipe APK"
      >
        <MenuItem value="" disabled>
          Tipe APK
        </MenuItem>
       
        <MenuItem value="Baliho Bambu 300x400">Baliho Bambu 300x400</MenuItem>
        <MenuItem value="Billboard">Billboard</MenuItem>
        <MenuItem value="Umbul-Umbul 70-300">Umbul-Umbul 70-300</MenuItem>
        <MenuItem value="Spanduk 300x100">Spanduk 300x100</MenuItem>
        <MenuItem value="Spanduk 700x100">Spanduk 700x100</MenuItem>
        <MenuItem value="Spanduk Warung">Spanduk Warung</MenuItem>
      </Select>
    </Grid>
               
      
               
                
                <Grid item>
      <TextField
         onChange={handleFormChange}
         value={formData.apk_size}
         name="apk_size"
        
        label="Ukuran APK"
        fullWidth
      />
    </Grid>
    <Grid item>
      <TextField
         onChange={handleFormChange}
         value={formData.apk_count}
         name="apk_count"
         type="number" 
        label="Jumlah APK"
        fullWidth
      />
    </Grid>
    <Grid item>
                  <Typography variant="h6" color="primary">Penempatan</Typography>
                </Grid>
                <Grid item>
      <TextField
        onChange={handleFormChange}
        value={formData.location_name}
        name="location_name"
       
        label="Nama Lokasi"
        fullWidth
      />
    </Grid>
    <Grid item>
      <TextField
        onChange={handleFormChange}
        value={formData.location_type}
        name="location_type"
       
        label="Tipe Lokasi"
        fullWidth
      />
    </Grid>
    <Grid item>
  <Autocomplete
    value={selectedProvince}
    onChange={(event, newValue) => setSelectedProvince(newValue)}
    options={provinces}
    getOptionLabel={(option) => option ? option.name : ''} 
    renderInput={(params) => <TextField {...params} label="Pilih Provinsi" />}
  />
</Grid>


<Grid item>
  <Autocomplete
    value={selectedKabupaten}
    onChange={(event, newValue) => setSelectedKabupaten(newValue)}
    options={kabupatenList}
    getOptionLabel={(option) => option ? option.name : ''} 
    renderInput={(params) => <TextField {...params} label="Pilih Kabupaten" />}
    disabled={!selectedProvince}
  />
</Grid>


<Grid item>
  <Autocomplete
    value={selectedKecamatan}
    onChange={(event, newValue) => setSelectedKecamatan(newValue)}
    options={kecamatanList}
    getOptionLabel={(option) => option ? option.name : ''} 
    renderInput={(params) => <TextField {...params} label="Pilih Kecamatan" />}
    disabled={!selectedKabupaten}
  />
</Grid>

<Grid item>
  <Autocomplete
    value={selectedKelurahan}
    onChange={(event, newValue) => setSelectedKelurahan(newValue)}
    options={kelurahanList}
    getOptionLabel={(option) => option ? option.name : ''} 
    renderInput={(params) => <TextField {...params} label="Pilih Kelurahan" />}
    disabled={!selectedKecamatan}
  />
</Grid>
    <Grid item>
      <TextField
        onChange={handleFormChange}
        value={formData.dusun}
        name="dusun"
       
        label="Dusun"
        fullWidth
      />
    </Grid>

  
    

    

    <Grid item>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
    <Button variant="outlined" onClick={handleCancel}>
                  Batal
                </Button>
    <Button variant="contained" type="submit">
        Kirim
      </Button>
      
      </Box>
    </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormPemasangan;