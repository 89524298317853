import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Button, Box, Grid, Card, CardContent, Typography, TextField, Select, MenuItem } from '@mui/material';

import { DateTimePicker } from '@mui/x-date-pickers';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import L from 'leaflet';


import { format } from 'date-fns';
import topaz from '../../../components/TokenPass';



const FormEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [mapCoordinates, setMapCoordinates] = useState({ lat: 0, lng: 0 });
  const [editData, setEditData] = useState({
    id: id,
    pic_name: '',
    pic_phone: '',
    installation_date: '',
    apk_code: '',
    apk_type: '',
    apk_size: '',
    apk_count: '',
    location_type: '',
    location_name: '',
    dusun: '',
    kelurahan: '',
    kecamatan: '',
    kabupaten: '',
    province: '',
    google_maps_link: '',
    created_by: '',
    latitude: '',
    longitude: ''
  });
  console.log(editData);







  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/id/${id}`);
      const data = response.data;

      const apiData = data.data[0];

      const formattedData = {
          
          ...apiData,
          pic_name: apiData.pic_name,
    pic_phone: apiData.pic_phone,
    installation_date: apiData.installation_date ? new Date(apiData.installation_date) : null,
    apk_code: apiData.apk_code,
    apk_type: apiData.apk_type,
    apk_size: apiData.apk_size,
    apk_count: apiData.apk_count,
    location_type: apiData.location_type,
    location_name: apiData.location_name,
    dusun: apiData.dusun,
    kelurahan: apiData.kelurahan,
    kecamatan: apiData.kecamatan,
    kabupaten: apiData.kabupaten,
    province: apiData.province,
    google_maps_link: apiData.google_maps_link,
    created_by: apiData.created_by,
    latitude: apiData.latitude || 0,
longitude: apiData.longitude || 0,

        };
  
        setEditData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

 
  

  

  const handleDateChange = (newValue, field) => {
    let formattedValue;
  
    if (field === 'installation_date') {
      formattedValue = format(newValue, 'yyyy-MM-dd HH:mm:ss');
    }
  
    setEditData({ ...editData, [field]: formattedValue });
  };

  const handleFormChange = (field, value) => {
    setEditData((prevData) => ({ ...prevData, [field]: value }));
  };
  
  const handleFormEdit = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
  
      for (const key in editData) {
        const value = editData[key];
  
        if (value !== undefined && value !== null) {
          if (key === 'installation_date') {
            const formattedDate = format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
            formData.append(key, formattedDate);
          } else {
            formData.append(key, value.toString());
          }
        }
      }
  
      const confirmed = window.confirm('Data will be edited. Are you sure the data is exactly right?');
  
      if (confirmed) {
        const response = await topaz.put(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        window.alert('Data has been edited successfully.');
        console.log(response.data);
        navigate('/data/installation');
      } else {
        window.alert('Data editing cancelled.');
      }
    } catch (error) {
      console.error('Error editing data:', error);
      window.alert(`Failed to edit data: ${error.message}`);
    }
  };

  const mapRef = useRef();
  const map = useRef(null);
  const marker = useRef(null);

  useEffect(() => {
    const { latitude, longitude } = editData;
  
 
    const isValidLatLng = !isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude));
  
    if (!map.current && isValidLatLng) {
      map.current = L.map(mapRef.current).setView([parseFloat(latitude), parseFloat(longitude)], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map.current);
  
      marker.current = L.marker([parseFloat(latitude), parseFloat(longitude)]).addTo(map.current);
  
      map.current.on('click', (e) => {
        const { lat, lng } = e.latlng;
        setMapCoordinates({ lat, lng });
  
    
        setEditData((prevEditData) => ({ ...prevEditData, latitude: lat, longitude: lng }));
  
     
        if (marker.current) {
          marker.current.setLatLng([lat, lng]);
        } else {  
          marker.current = L.marker([lat, lng]).addTo(map.current);
        }
      });
    }
  }, [editData.latitude, editData.longitude]);
  
  
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [kabupatenList, setKabupatenList] = useState([]);
  const [selectedKabupaten, setSelectedKabupaten] = useState(null);

  const [kecamatanList, setKecamatanList] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState(null);

  const [kelurahanList, setKelurahanList] = useState([]);
  const [selectedKelurahan, setSelectedKelurahan] = useState(null);
  
  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/province/all`);
        if (response.data.status === 'success') {
          setProvinces(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };
  
    fetchProvinces();
  }, []);
  
  
  
  useEffect(() => {
    if (selectedProvince) {
      const fetchKabupaten = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten/name/${selectedProvince}`);
          if (response.data.status === 'success') {
            setKabupatenList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kabupaten:', error);
        }
      };
  
      fetchKabupaten();
    }
  }, [selectedProvince]);
  
  useEffect(() => {
    if (selectedKabupaten) {
      const fetchKecamatan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kecamatan/name/${selectedKabupaten}`);
          if (response.data.status === 'success') {
            setKecamatanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kecamatan:', error);
        }
      };
  
      fetchKecamatan();
    }
  }, [selectedKabupaten]);

  useEffect(() => {
    if (selectedKecamatan) {
      const fetchKelurahan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kelurahan/name/${selectedKecamatan}`);
          if (response.data.status === 'success') {
            setKelurahanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kabupaten:', error);
        }
      };
  
      fetchKelurahan();
    }
  }, [selectedKecamatan]);

  useEffect(() => {
    if (editData.province) {
      setSelectedProvince(editData.province);
    }
  }, [editData.province]);
  
  useEffect(() => {
    if (editData.kabupaten) {
      setSelectedKabupaten(editData.kabupaten);
    }
  }, [editData.kabupaten]);
  
  useEffect(() => {
    if (editData.kecamatan) {
      setSelectedKecamatan(editData.kecamatan);
    }
  }, [editData.kecamatan]);

  useEffect(() => {
    if (editData.kelurahan) {
      setSelectedKelurahan(editData.kelurahan);
    }
  }, [editData.kelurahan]);
  

  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/installation');
    }
  };
  

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
    <Grid item xs={12} sm={10} md={8}> 
      <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
        <CardContent>
            <form onSubmit={handleFormEdit}>
              <Grid container spacing={3} direction="column">
               
             

                <Grid item>
        <div ref={mapRef} style={{ height: '480px', width: '800px', margin: '0 auto' }} />
      </Grid>
      <Grid item>
                    <Grid container spacing={3}>
                        <Grid item xs={true}>
                            <TextField
                         
                                placeholder="Place Google Maps URL Here"
                                label='Use Google Maps URL (OPTIONAL)'
                                fullWidth />
                        </Grid>
                        <Grid item>
                            <Button type='button' variant='contained' sx={{ height: '56px', paddingLeft: '16px', paddingRight: '16px' }}>Get Location</Button>
                        </Grid>
                    </Grid>
                </Grid>
      <Grid item container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <TextField
            onChange={handleFormChange}
            value={editData.latitude}
            name="lat"
          
            fullWidth
           
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleFormChange}
            value={editData.longitude}
            name="lng"
           
            fullWidth
            
          />
        </Grid>
      </Grid>
                
                
                
                <Grid item>
                  <Typography variant="h6" color="primary">Informasi Pribadi</Typography>
                </Grid>
              
                <Grid item>
                  <TextField
                   onChange={(e) => handleFormChange('pic_name', e.target.value)}
                   value={editData ? editData.pic_name : ''}
                   name="pic_name"
                   label="Nama PIC"
                   fullWidth
                  />
                </Grid>
                <Grid item >
                  
                  
                  <TextField
                    onChange={(e) => handleFormChange('pic_phone', e.target.value)}
                    value={editData ? editData.pic_phone : ''}
                    name="pic_phone"
                    label="Nomor PIC"
                    type="number" 
                    fullWidth
                  />
                
              </Grid>
                
 
      <Grid item>
                  <Typography variant="h6" color="primary">Pemasangan APK</Typography>
                </Grid>
                <Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      label="Tanggal Pemasangan"
      value={editData.installation_date ? new Date(editData.installation_date) : null}
      onChange={(newValue) => handleDateChange(newValue, 'installation_date')}
      renderInput={(params) => <TextField {...params} />}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>
                
                <Grid item>
      <TextField
         onChange={(e) => handleFormChange('apk_code', e.target.value)}
         value={editData ? editData.apk_code : ''}
         name="apk_code"
         label="Kode APK"
         fullWidth
      />
    </Grid>
    <Grid item>
  <Select
    value={editData.apk_type || ''}
    onChange={(e) => handleFormChange('apk_type', e.target.value)}
    displayEmpty
    fullWidth
    placeholder="Tipe APK"
  >
    <MenuItem value="" disabled>
      Tipe APK
    </MenuItem>
    <MenuItem value="Baliho Bambu 300x400">Baliho Bambu 300x400</MenuItem>
        <MenuItem value="Billboard">Billboard</MenuItem>
        <MenuItem value="Umbul-Umbul 70-300">Umbul-Umbul 70-300</MenuItem>
        <MenuItem value="Spanduk 300x100">Spanduk 300x100</MenuItem>
        <MenuItem value="Spanduk 700x100">Spanduk 700x100</MenuItem>
        <MenuItem value="Spanduk Warung">Spanduk Warung</MenuItem>
  </Select>
</Grid>
    <Grid item>
      <TextField
         onChange={(e) => handleFormChange('apk_size', e.target.value)}
         value={editData ? editData.apk_size : ''}
         name="apk_size"
         label="Ukuran APK"
         fullWidth
      />
    </Grid>
    <Grid item>
      <TextField
         onChange={(e) => handleFormChange('apk_count', e.target.value)}
         value={editData ? editData.apk_count : ''}
         name="apk_count"
         label="Jumlah APK"
         type="number" 
         fullWidth
      />
    </Grid>
    <Grid item>
                  <Typography variant="h6" color="primary">Penempatan</Typography>
                </Grid>
                <Grid item>
      <TextField
         onChange={(e) => handleFormChange('location_name', e.target.value)}
         value={editData ? editData.location_name : ''}
         name="location_name"
         label="Nama Lokasi"
         fullWidth
      />
    </Grid>
    <Grid item>
      <TextField
         onChange={(e) => handleFormChange('location_type', e.target.value)}
         value={editData ? editData.location_type : ''}
         name="location_type"
         label="Tipe Lokasi"
         fullWidth
      />
    </Grid>
    <Grid item>
    <Autocomplete
  options={provinces}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedProvince(newValue);
   
    setSelectedKabupaten(null);
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, province: newValue ? newValue.name : null, kabupaten: null, kecamatan: null, kelurahan: null }));
  }}
  value={provinces.find((province) => province.name === selectedProvince) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Provinsi"
      placeholder="Pilih Provinsi"
    />
  )}
/>

</Grid>
   


    <Grid item>
    <Autocomplete
  options={kabupatenList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKabupaten(newValue);
    setSelectedKecamatan(null);
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, kabupaten: newValue ? newValue.name : null, kecamatan: null, kelurahan: null }));
  }}
  value={kabupatenList.find((kabupaten) => kabupaten.name === selectedKabupaten) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kabupaten"
      placeholder="Pilih Kabupaten"
      disabled={!selectedProvince}
    />
  )}
/>

</Grid>

<Grid item>
<Autocomplete
  options={kecamatanList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKecamatan(newValue);
  
    setSelectedKelurahan(null);
    setEditData((prevData) => ({ ...prevData, kecamatan: newValue ? newValue.name : null, kelurahan: null }));
  }}
  value={kecamatanList.find((kecamatan) => kecamatan.name === selectedKecamatan) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kecamatan"
      placeholder="Pilih Kecamatan"
      disabled={!selectedKabupaten}
    />
  )}
/>

</Grid>
   
<Grid item>
<Autocomplete
  options={kelurahanList}
  getOptionLabel={(option) => option.name}
  onChange={(event, newValue) => {
    setSelectedKelurahan(newValue);
    setEditData((prevData) => ({ ...prevData, kelurahan: newValue ? newValue.name : null }));
  }}
  value={kelurahanList.find((kelurahan) => kelurahan.name === selectedKelurahan) || null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Pilih Kelurahan"
      placeholder="Pilih Kelurahan"
      disabled={!selectedKecamatan}
    />
  )}
/>

</Grid>
    <Grid item>
      <TextField
       onChange={(e) => handleFormChange('dusun', e.target.value)}
       value={editData ? editData.dusun : ''}
       name="dusun"
       label="Dusun"
       fullWidth
      />
    </Grid>
    

    <Grid item>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
    <Button variant="outlined" onClick={handleCancel}>
                  Batal
                </Button>
    <Button variant="contained" type="submit">
        Ubah
      </Button>
      
      </Box>
    </Grid>
      
    </Grid>
              
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormEdit;