import { Avatar, IconButton, Card, Divider, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const InstallDetailPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const handleGoBack = () => {
        navigate('/data/installation');
    };
  
    console.log(state)
    return (
        <Card>
            <Grid container>
                <Grid item xs={3} sx={{ background: '#1976d2', padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
                <IconButton style={{ backgroundColor: 'red' }} onClick={handleGoBack}>
    <ArrowBackIcon style={{ color: 'white' }} />
  </IconButton>
                    <Avatar sx={{ width: '150px', height: '150px', margin: 'auto' }} />
                    <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: '22px', color: '#e3f2fd', marginTop: '16px' }}>{state.pic_name}</Typography>
                    <Divider sx={{ fontSize: '12px' }} />

                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Telepon</Typography>
                    <Typography>{state.pic_phone}</Typography>
                </Grid>
                <Divider flexItem />
                <Grid item xs={true} sx={{ padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
                <MapContainer
                        center={[
                            state.latitude || 0,
                            state.longitude || 0,
                        ]}
                        zoom={15}
                        scrollWheelZoom={false}
                        style={{ width: '100%', height: '300px', maxHeight: '20vh', zIndex: 0 }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[state.latitude || 0, state.longitude || 0]}>
                            <Tooltip>
                                <div style={{ fontWeight: 'bold' }}>
                                    {state.latitude || 0}, {state.longitude || 0}
                                </div>
                            </Tooltip>
                        </Marker>
                    </MapContainer>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Tanggal Pemasangan</Typography>
                    <Typography>{new Date(state.installation_date).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kode APK</Typography>
                    <Typography>{state.apk_code}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Tipe APK</Typography>
                    <Typography>{state.apk_type}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Ukuran APK</Typography>
                    <Typography>{state.apk_size}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Jumlah APK</Typography>
                    <Typography>{state.apk_count}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Dusun</Typography>
                    <Typography>{state.dusun}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kelurahan</Typography>
                    <Typography>{state.kelurahan}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kecamatan</Typography>
                    <Typography>{state.kecamatan}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kabupaten</Typography>
                    <Typography>{state.kabupaten}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Provinsi</Typography>
                    <Typography>{state.province}</Typography>

                    
                    
                </Grid>
            </Grid>
        </Card>
    )
}

export default InstallDetailPage