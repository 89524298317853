import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import voters from '../../assets/img/voters.svg'
import installation from '../../assets/img/installation.svg'
import { useNavigate } from 'react-router-dom'

const dataCardList = [
    {
        name: 'Analisis Dukungan Masyarakat',
        img: voters,
        link: '/analytics/voters'
    },
    {
        name: 'Analisis Pemasangan APK',
        img: installation,
        link: '/analytics/apk'
    },
]

const DataCard = ({ name, img, link }) => {
    const navigate = useNavigate()
    return (
        <Card sx={{ boxShadow: 'none', borderRadius: '8px', cursor: 'pointer', '&:hover': { transform: 'scale(1.03)' }, '&:active': { transform: 'scale(0.98)' } }} onClick={() => navigate(link)}>
            <CardContent sx={{ paddingBottom: '16px !important', fontSize: '20px', fontWeight: '500' }}>
                <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', marginBottom: '16px' }}>{name}</Typography>
                <img src={img} alt='img-card' style={{ width: '100%', height: '200px' }} />
            </CardContent>
        </Card>
    )
}

const AnalyticsPage = () => {
    return (
        <>
            <Grid container spacing={{ xs: 1, md: 3 }}>
                <Grid item xs={12}>
                    <Card sx={{ boxShadow: 'none', borderRadius: '8px' }}>
                        <CardContent sx={{ paddingBottom: '16px !important', fontSize: '20px', fontWeight: '500' }}>Analisis</CardContent>
                    </Card>
                </Grid>
                {dataCardList.map(x =>
                    <Grid key={x.name} item xs={12} md={6} lg={4}><DataCard {...x} /></Grid>
                )}
            </Grid>
        </>
    )
}

export default AnalyticsPage