import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthProvider';
import { SnackbarProvider } from 'notistack'; // Import SnackbarProvider from Notistack

import FormG from './pages/forms/general/FormG';
import TableG from './pages/tables/TableG';
import LoginPage from './pages/LoginPage';
import EditFormG from './pages/forms/general/EditFormG';
import HomePage from './pages/HomePage';

import CustomAppBar from './components/CustomAppBar';
import Layout from './components/Layout';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <SnackbarProvider maxSnack={3}> {/* Wrap your routes with SnackbarProvider */}
          <AppRouting />
        </SnackbarProvider>
      </Router>
    </AuthProvider>
  );
};

const AppRouting = () => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return (
      <div style={{ display: 'flex' }}>
        <CustomAppBar />
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/table-general" element={<TableG />} />
          <Route path="/form-general" element={<FormG />} />
          <Route path="/fg-edit/:id" element={<EditFormG />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
    </Routes>
  );
};

export default App;
