import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        text: {
            primary: 'rgb(18, 25, 38)',
        },
    },
});

export default theme;