import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, MenuItem, Divider, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import StyledMenu from './StyledMenu';
import { useAuth } from '../components/AuthProvider'; 

const CustomAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth(); 
  const navigate = useNavigate(); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
   
    localStorage.removeItem('user');
    logout();
    navigate('/'); 
  };

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">
  <Link to="/" style={{
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer',
  }}>
    Eagle Eye
  </Link>
</Typography>
        </Box>
        <IconButton
          edge="end"
          id="customized-button"
          aria-controls={open ? 'customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <MenuIcon />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
         <MenuItem component={Link} to="/home" disableRipple>
            <HomeIcon />
            Home
          </MenuItem>
          <MenuItem component={Link} to="/form" disableRipple>
            <CreateIcon />
            Form
          </MenuItem>
          <MenuItem component={Link} to="/table" disableRipple>
            <BackupTableIcon />
            Data Table
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleLogout} disableRipple>
            <LogoutIcon />
            Logout
          </MenuItem>
        </StyledMenu>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
