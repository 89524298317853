import { PieChart as PieChartIcon, StackedBarChart } from '@mui/icons-material';
import { Button, ButtonGroup, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Bar, BarChart, Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#00cbfe'];
const PASLON = ['Anies & Muhaimin', 'Ganjar & Mahfud', 'Prabowo & Gibran']

const CardDiagram = ({ data, title, distinct, isProvince }) => {
    const [dataPie, setDataPie] = useState([])
    const [dataBar, setDataBar] = useState([])
    const [buttonActive, setButtonActive] = useState(true)
    const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
        const RADIAN = Math.PI / 180;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>)
    }
    const handleChangeChart = () => setButtonActive(!buttonActive)

    useEffect(() => {
        let a, b, c, d, e
        if (title === 'Sebaran Dukungan') {
            a = b = c = d = 0
            data.map(x => {
                a += x[0] === null ? 0 : Number(x[0])
                b += x[1] === null ? 0 : Number(x[1])
                c += x[2] === null ? 0 : Number(x[2])
                d += x[3] === null ? 0 : Number(x[3])
            })
            setDataPie([
                { name: 'Belum Menentukan', value: Number(a) },
                { name: PASLON[0], value: Number(b) },
                { name: PASLON[1], value: Number(c) },
                { name: PASLON[2], value: Number(d) }
            ])

            data.map(x => {
                a += x[0] === null ? 0 : Number(x[0])
                b += x[1] === null ? 0 : Number(x[1])
                c += x[2] === null ? 0 : Number(x[2])
                d += x[3] === null ? 0 : Number(x[3])
            })
            setDataPie([
                { name: 'Belum Menentukan', value: Number(a) },
                { name: PASLON[0], value: Number(b) },
                { name: PASLON[1], value: Number(c) },
                { name: PASLON[2], value: Number(d) }
            ])
            const databarNew = data.map(x => {
                return { name: x.place_name, '0': x[0], '1': x[1], '2': x[2], '3': x[3] }
            })
            setDataBar(databarNew)
        } else if (title === 'Sebaran Pendukung Berdasarkan Usia') {
            a = b = c = d = e = 0
            data.map(x => {
                a += x[0] === null ? 0 : Number(x[0])
                b += x[1] === null ? 0 : Number(x[1])
                c += x[2] === null ? 0 : Number(x[2])
                d += x[3] === null ? 0 : Number(x[3])
                e += x[4] === null ? 0 : Number(x[4])
            })
            setDataPie([
                { name: '17-25th', value: Number(a) },
                { name: '26-35th', value: Number(b) },
                { name: '36-45th', value: Number(c) },
                { name: '46-60th', value: Number(d) },
                { name: '>60th', value: Number(e) }
            ])
            const databarNew = data.map(x => {
                return { name: x.place_name, '0': x[0], '1': x[1], '2': x[2], '3': x[3], '4': x[4] }
            })
            setDataBar(databarNew)
        } else if (title === 'Sebaran Pendukung Berdasarkan Jenis Kelamin') {
            a = b = 0
            data.map(x => {
                a += x.male === null ? 0 : Number(x.male)
                b += x.female === null ? 0 : Number(x.female)
            })
            setDataPie([
                { name: 'Laki-laki', value: Number(a) },
                { name: 'Perempuan', value: Number(b) }
            ])

            const databarNew = data.map((x, idx) => {
                return { name: x.place_name, '0': Number(x.male), '1': Number(x.female) }
            })
            setDataBar(databarNew)
        } else if (title === 'Sebaran Pendukung Berdasarkan Pendidikan') {
            a = b = c = d = e = 0
            data.map(x => {
                a += x[0] === null ? 0 : Number(x[0])
                b += x[1] === null ? 0 : Number(x[1])
                c += x[2] === null ? 0 : Number(x[2])
                d += x[3] === null ? 0 : Number(x[3])
                e += x[4] === null ? 0 : Number(x[4])
            })
            setDataPie([
                { name: 'Belum Sekolah', value: Number(a) },
                { name: 'SD', value: Number(b) },
                { name: 'SMP', value: Number(c) },
                { name: 'SMA', value: Number(d) },
                { name: 'PT', value: Number(e) }
            ])

            const databarNew = data.map((x, idx) => {
                return { name: x.place_name, '0': x[0], '1': x[1], '2': x[2], '3': x[3], '4': x[4] }
            })
            setDataBar(databarNew)
        } else if (title === 'Sebaran Pendukung Berdasarkan Pekerjaan') {
            setDataPie(distinct.map(x => { return { name: x.occupation, value: Number(x.total) } }))

            const databarNew = data.map((x, idx) => {
                return { name: x.place_name, '0': x[0], '1': x[1], '2': x[2], '3': x[3], '4': x[4] }
            })
            setDataBar(databarNew)
        }

    }, [data])
    return (
        <Card>
            <CardContent>
                <Grid container justifyContent='space-between' spacing={1}>
                    <Grid item xs={true}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', opacity: '0.7', minHeight: '48px' }}>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonGroup variant='outlined'>
                            <Button disabled={buttonActive} onClick={handleChangeChart}><PieChartIcon /></Button>
                            <Button disabled={!buttonActive} onClick={handleChangeChart}><StackedBarChart /></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <ResponsiveContainer width="100%" height={300}>
                    {buttonActive ?
                        <PieChart>
                            <Pie
                                data={dataPie}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                legendType='line'
                            >
                                {dataPie.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend align="left" iconSize={12}
                                payload={
                                    dataPie.map(
                                        (item, index) => ({
                                            id: item.name,
                                            type: "rect",
                                            value: `${item.name} (${item.value})`,
                                            color: COLORS[index]
                                        })
                                    )
                                } />
                        </PieChart> :
                        <BarChart
                            data={dataBar}
                            layout='vertical'
                        >
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="name" width={80} style={{ fontSize: '12px' }} />
                            <Legend align="left" iconSize={12}
                                payload={
                                    dataPie.map(
                                        (item, index) => ({
                                            id: item.name,
                                            type: "rect",
                                            value: `${item.name} (${item.value})`,
                                            color: COLORS[index]
                                        })
                                    )
                                } />
                            <Bar dataKey="0" fill={COLORS[0]} stackId="stack">
                                <LabelList dataKey="0" position="middle" fill='black' style={{ fontSize: '14px' }} />
                            </Bar>
                            <Bar dataKey="1" fill={COLORS[1]} stackId="stack">
                                <LabelList dataKey="1" position="middle" fill='black' style={{ fontSize: '14px' }} />
                            </Bar>
                            <Bar dataKey="2" fill={COLORS[2]} stackId="stack">
                                <LabelList dataKey="2" position="middle" fill='black' style={{ fontSize: '14px' }} />
                            </Bar>
                            <Bar dataKey="3" fill={COLORS[3]} stackId="stack">
                                <LabelList dataKey="3" position="middle" fill='black' style={{ fontSize: '14px' }} />
                            </Bar>
                            <Bar dataKey="4" fill={COLORS[4]} stackId="stack">
                                <LabelList dataKey="4" position="middle" fill='black' style={{ fontSize: '14px' }} />
                            </Bar>
                        </BarChart>
                    }
                </ResponsiveContainer>
            </CardContent>
        </Card>
    )
}

export default CardDiagram