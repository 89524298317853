import React from 'react'

import { CircleMarker, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Maps = ({ data }) => {
    return (
        <MapContainer center={[-8.674330665629418, 117.3007753818085]} zoom={8} style={{ width: '100%', height: '100%', minHeight: '300px', zIndex: 0 }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data.map(x =>
                <CircleMarker
                    key={x.place_name}
                    center={[x.latitude, x.longitude]}
                    radius={20 * (x.total / 100)}
                    fillOpacity={0.5}
                    stroke={false}
                />
            )}
        </MapContainer>
    )
}

export default Maps