import { Avatar, IconButton, Card, Divider, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const VotersDetailPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const handleGoBack = () => {
        navigate('/data/voters');
    };
    const religion = ['Tidak tahu', 'Islam', 'Kristen', 'Hindu', 'Budha', 'Lainnya']
    const supportDirectionMapping = {
        0: 'Tidak Memilih',
        1: 'Anies & Muhaimin',
        3: 'Ganjar & Mahfud',
        2: 'Prabowo & Gibran',
    };
    const installationWillingnessMapping = {
        0: 'Tidak',
        1: 'Ya',
    };
    console.log(state)
    return (
        <Card>
            <Grid container>
                <Grid item xs={3} sx={{ background: '#1976d2', padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
                
  <IconButton style={{ backgroundColor: 'red' }} onClick={handleGoBack}>
    <ArrowBackIcon style={{ color: 'white' }} />
  </IconButton>

                    <Avatar sx={{ width: '150px', height: '150px', margin: 'auto' }} />
                    <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: '22px', color: '#e3f2fd', marginTop: '16px' }}>{state.full_name}</Typography>
                    <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: '14px', color: '#e3f2fd' }}>KTP {state.id_card_number}</Typography>
                    <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: '14px', color: '#e3f2fd', marginBottom: '16px' }}>KK {state.id_card_number}</Typography>
                    <Divider sx={{ fontSize: '12px' }} />
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '16px', color: '#e3f2fd' }}>Tanggal Lahir</Typography>
                    <Typography>{new Date(state.date_of_birth).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Jenis Kelamin</Typography>
                    <Typography>{state.gender}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Agama</Typography>
                    <Typography>{state.religion}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Pekerjaan</Typography>
                    <Typography>{state.occupation}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Telepon</Typography>
                    <Typography>{state.phone_number}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px', color: '#e3f2fd' }}>Email</Typography>
                    <Typography>{state.email}</Typography>
                    
                </Grid>
                <Divider flexItem />
                <Grid item xs={true} sx={{ padding: { xs: '16px 8px', md: '16px', lg: '24px' } }}>
                <MapContainer
                        center={[
                            state.latitude || 0, 
                            state.longitude || 0,
                        ]}
                        zoom={15}
                        scrollWheelZoom={false}
                        style={{ width: '100%', height: '300px', maxHeight: '20vh', zIndex: 0 }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[state.latitude || 0, state.longitude || 0]}>
                            <Tooltip>
                                <div style={{ fontWeight: 'bold' }}>
                                    {state.latitude || 0}, {state.longitude || 0}
                                </div>
                            </Tooltip>
                        </Marker>
                    </MapContainer>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Tanggal Kunjungan</Typography>
                    <Typography>{new Date(state.visit_date).toLocaleDateString('id-ID', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>RT</Typography>
                    <Typography>{state.rt}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>RW</Typography>
                    <Typography>{state.rw}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Dusun</Typography>
                    <Typography>{state.dusun}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Desa/Kelurahan</Typography>
                    <Typography>{state.kelurahan}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kecamatan</Typography>
                    <Typography>{state.kecamatan}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kabupaten</Typography>
                    <Typography>{state.kabupaten}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Provinsi</Typography>
                    <Typography>{state.province}</Typography>

                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Arah Dukungan</Typography>
                    <Typography>{supportDirectionMapping[state.support_direction]}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Kesediaan Dipasang APK</Typography>
                    <Typography>{installationWillingnessMapping[state.installation_willingness_if_gp]}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Jenis APK Diberikan</Typography>
                    <Typography>{state.type_equipment_given_if_gp}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Jumlah APK Diberikan</Typography>
                    <Typography>{state.count_equipment_given_if_gp}</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Foto Orang</Typography>
{state.person_photo_if_gp ? (
  <img src={`${apiUrl}/${state.person_photo_if_gp}`} alt="Foto Orang" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '8px' }} />
) : (
  <Typography>Tidak Tersedia</Typography>
)}
<Typography sx={{ fontSize: '12px', fontWeight: '600', opacity: '0.8', marginTop: '8px' }}>Foto KTP</Typography>
{state.id_card_photo_if_gp ? (
  <img src={`${apiUrl}/${state.id_card_photo_if_gp}`} alt="Foto KTP" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '8px' }} />
) : (
  <Typography>Tidak Tersedia</Typography>
)}
                </Grid>
            </Grid>
        </Card>
    )
}

export default VotersDetailPage