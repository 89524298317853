import React, { useState } from 'react';
import { Paper, Button, Snackbar, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { handleGoogleSignIn } from '../components/Auth';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../assets/img/Logoset_01.png';
import { Alert } from '@mui/material';

const LoginPage = () => {
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGoogleSignInClick = async () => {
    try {
      setLoading(true);
      const success = await handleGoogleSignIn();

      if (success) {
        navigate('/dashboard');
      } else {
        setErrorAlert('Failed to sign in with Google');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorAlert = () => {
    setErrorAlert(null);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper style={{ padding: '20px', minWidth: '300px', textAlign: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
        <h2 style={{ marginBottom: '20px' }}>Login</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoogleSignInClick}
          startIcon={<LoginIcon />}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Sign in with Google'}
        </Button>

        <Snackbar open={!!errorAlert} autoHideDuration={6000} onClose={handleCloseErrorAlert}>
          <Alert severity="error" onClose={handleCloseErrorAlert}>
            {errorAlert}
          </Alert>
        </Snackbar>
      </Paper>
    </div>
  );
};

export default LoginPage;
