import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton, Tooltip,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getDataInstall } from '../../utils/ApiList'
import { Pageview, BorderColor, DeleteForever, Create, ExpandMore, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import topaz from '../../components/TokenPass';
import * as XLSX from 'xlsx';
import DialogConfirmation from '../../components/DialogConfirmation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const InstallDataPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [exportError, setExportError] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenPaginationMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClosePaginationMenu = () => setAnchorEl(null);
  const handleChangePage = (e, value) => setPage(value);
  const handleSearch = (e) => setSearch(e.target.value);
  const handleChangeLimit = (value) => {
    setLimit(value);
    setAnchorEl(null);
  };

  const handleAdd = () => {
    navigate('/form-install');
  };

  const handleView = (data) => {
    navigate('/install-detail', { state: data });
  };

  const handleEdit = (id) => {
    const rowDataToEdit = data.find((row) => row.id === id);
    navigate(`/fi-edit/${id}`);
  };

  const [dialogConfirmation, setDialogConfirmation] = useState(null);

  const handleDelete = (id) => {
    const confirmationDialog = (
      <DialogConfirmation
        type='error'
        header='Hapus Data Pemasangan?'
        desc='Anda yakin mau menghapus data pemasangan ini? Data yang dihapus tidak dapat dikembalikan!'
        buttonDesc={{ cancel: 'Tidak', confirm: 'Ya, Hapus!' }}
        handleClose={() => setDialogConfirmation(null)}
        confirm={() => handleConfirmDelete(id)}
      />
    );

    setDialogConfirmation(confirmationDialog);
  };

  const handleConfirmDelete = (id) => {
    
    topaz
      .delete(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/id/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const updatedData = data.filter((item) => item.id !== id);
          setData(updatedData);
        } else {
          console.error('Failed to delete data');
        }
      })
      .catch((error) => {
        console.error('Error deleting data:', error);
      });

    setDialogConfirmation(null);
  };

  useEffect(() => {
    getDataInstall(`?page=${page}&limit=${limit}${search !== '' ? `&pic_name=${search}` : ''}`).then((response) => {
      setData(response.data.data);
      setMaxPage(response.data.totalPages);
    });
  }, [page, limit, search]);

  const handleExportToExcel = async () => {
    try {
      setLoading(true);
      setExportError(null);

      const apiUrl = `${process.env.REACT_APP_API_URL}/api/campaign-tool-installation`;

      const firstPageResponse = await topaz.get(apiUrl);
      const totalItems = parseInt(firstPageResponse.data.totalItems);

      const totalPages = Math.ceil(totalItems / 100);

      const allData = [];
      for (let page = 1; page <= totalPages; page++) {
        const pageResponse = await topaz.get(`${apiUrl}?page=${page}`);
        allData.push(...pageResponse.data.data);
      }

      const headers = Object.keys(allData[0]);
      const dataToExport = [headers, ...allData.map((item) => headers.map((header) => item[header]))];

      const ws = XLSX.utils.aoa_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, 'exported_eagleeye_data_install.xlsx');

      setLoading(false);
    } catch (error) {
      console.error('Error fetching or exporting data:', error);
      setExportError('Error exporting data. Please try again.');
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate('/data');
};

  return (
    <Card>
      <CardContent>
  <Grid container justifyContent="space-between" alignItems="center">
    <Grid container item justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item>
     
      <Tooltip title="Kembali" arrow>
  <IconButton style={{ backgroundColor: 'red' }} onClick={handleGoBack}>
    <ArrowBackIcon style={{ color: 'white' }} />
  </IconButton>
  </Tooltip>
      </Grid>
      <Grid container item direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>Data Pemasangan APK</Typography>
      </Grid>
      </Grid>
    </Grid>
    <Grid container item justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <Button variant="contained" color="warning" onClick={handleAdd} startIcon={<Create />}>
          Add
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="success" onClick={handleExportToExcel} startIcon={<GetApp />}>
          Export to Excel
        </Button>
      </Grid>
      <Grid item>
        <TextField label="Search" variant="outlined" value={search} onChange={handleSearch} size="small" />
      </Grid>
    </Grid>
  </Grid>
</CardContent>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="1%">#</TableCell>
              <TableCell width="30%">Kode APK</TableCell>
              <TableCell width="30%">PIC</TableCell>
              <TableCell width="30%">Tanggal Pemasangan</TableCell>
              <TableCell width="1%" align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((x, idx) => (
              <TableRow key={x.id}>
                <TableCell>{(page - 1) * limit + idx + 1}</TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{x.apk_code}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{x.pic_name}</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'rgb(105, 117, 134)' }}>{x.pic_phone}</Typography>
                </TableCell>
                <TableCell>{moment(x.installation_date).format('DD-MM-YYYY HH:mm')}</TableCell>
                <TableCell>
  <div style={{ display: 'flex', gap: '8px' }}>
    <Button sx={{ minWidth: '100px', width: '100px' }} variant="contained" onClick={() => handleEdit(x.id)} startIcon={<BorderColor />}>
      Edit
    </Button>
    <Button sx={{ minWidth: '100px', width: '100px' }} variant="contained" color="success" onClick={() => handleView(x)} startIcon={<Pageview />}>
      Lihat
    </Button>
    <Button sx={{ minWidth: '100px', width: '100px' }} variant="contained" color="error" onClick={() => handleDelete(x.id)} startIcon={<DeleteForever />}>
      Hapus
    </Button>
  </div>
</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Pagination count={maxPage} page={page} onChange={handleChangePage} color="primary" />
          </Grid>
          <Grid item>
            <Button sx={{ textTransform: 'none' }} endIcon={<ExpandMore />} onClick={handleOpenPaginationMenu}>
              {`${limit} Rows`}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClosePaginationMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleChangeLimit('10')}>10 Rows</MenuItem>
              <MenuItem onClick={() => handleChangeLimit('20')}>20 Rows</MenuItem>
              <MenuItem onClick={() => handleChangeLimit('50')}>50 Rows</MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999,
            }}
          >
            <CircularProgress />
          </div>
        )}
        {exportError && (
          <Typography color="error" sx={{ marginLeft: '10px' }}>
            {exportError}
          </Typography>
        )}
        {dialogConfirmation}
      </CardContent>
    </Card>
  );
};

export default InstallDataPage;
