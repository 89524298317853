import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, TextField, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


import topaz from '../../components/TokenPass';
import { Pageview, BorderColor, DeleteForever } from '@mui/icons-material';
import { Alert } from '@mui/material';
import DialogConfirmation from '../../components/DialogConfirmation';


const rowsPerPage = 10;

const TableG = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [editData, setEditData] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [dialogConfirmation, setDialogConfirmation] = useState(null)

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleEdit = (id) => {

    const rowDataToEdit = apiData.find((row) => row.id === id);
    setEditData(rowDataToEdit);

    navigate(`/fg-edit/${id}`);
  };

  const handleView = (data) => {
    navigate('/voters-detail', { state: data })
  }

  const handleDelete = async (id) => {
    setDialogConfirmation(
      <DialogConfirmation
        type='error'
        header='Hapus Data Pendukung?'
        desc='Anda yakin mau menghapus data pendukung ini? Data yang dihapus tidak dapat dikembalikan!'
        buttonDesc={{ cancel: 'Tidak', confirm: 'Ya, Hapus!' }}
        handleClose={() => setDialogConfirmation(null)}
        confirm={() => alert(`hapus data ${id}`)} />
    )
    // const confirmed = window.confirm('Are you sure you want to delete this?');

    // if (confirmed) {
    //   try {
    //     const deleteUrl = `${process.env.REACT_APP_API_URL}/api/general-election-voters/id/${id}`;
    //     await topaz.delete(deleteUrl);

    //     const updatedData = apiData.filter((row) => row.id !== id);
    //     setApiData(updatedData);


    //     setAlertMessage(`Row with ID ${id} deleted successfully.`);
    //     setAlertOpen(true);
    //   } catch (error) {

    //     setAlertMessage('Error deleting data. Please try again.');
    //     setAlertOpen(true);
    //   }
    // }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };



  const fetchTableData = async (page, limit) => {
    const calculatedPage = Math.floor(page / rowsPerPage) + 1;
    const url = `${process.env.REACT_APP_API_URL}/api/general-election-voters/page/${calculatedPage}/max/${limit}`;

    try {
      const response = await topaz.get(url);
      const data = response.data;
      return {
        data: data.data || [],
        totalItems: parseInt(data.totalItems, 10) || 0,
      };
    } catch (error) {
      console.error('Error fetching table data:', error);
      return {
        data: [],
        totalItems: 0,
      };
    }
  };

  const searchTableData = async (searchQuery) => {
    const url = `${process.env.REACT_APP_API_URL}/api/general-election-voters/id/${searchQuery}`;

    try {
      const response = await topaz.get(url); // Replace axios with topaz
      const data = response.data;
      const totalItems = data.data ? data.data.length : 0;

      return {
        data: data.data || [],
        totalItems: totalItems,
      };
    } catch (error) {
      console.error('Error searching table data:', error);
      return {
        data: [],
        totalItems: 0,
      };
    }
  };



  useEffect(() => {

    const calculatedOffset = Math.floor(page * rowsPerPage);
    if (searchQuery) {
      console.log('Search API hit');
      searchTableData(searchQuery)
        .then((data) => {
          setApiData(data.data);
          setTotalItems(data.totalItems);
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
        });
    } else {
      const url = `${process.env.REACT_APP_API_URL}/api/general-election-voters/page/${calculatedOffset}/max/${rowsPerPage}`;

      fetchTableData(calculatedOffset, rowsPerPage)
        .then((data) => {
          setApiData(data.data);
          setTotalItems(data.totalItems);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [page, searchQuery]);

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toISOString().split('T')[0];
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <Container>
      {dialogConfirmation}
      <Alert severity={alertMessage.includes('Error') ? 'error' : 'success'} onClose={handleAlertClose} open={alertOpen}>
        {alertMessage}
      </Alert>
      <Box style={{ marginBottom: '80px' }}></Box>
      <TextField
        label="Search by ID"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
      />


      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>KTP</TableCell>
              <TableCell>Visit Date</TableCell>
              <TableCell>Action</TableCell> {/* New Table Header Cell for Actions */}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(apiData) && apiData.length > 0 ? (
              apiData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.full_name}</TableCell>
                  <TableCell>{row.id_card_number}</TableCell>
                  <TableCell>{formatDate(row.visit_date)}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(row.id)}
                      startIcon={<BorderColor />}
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      style={{ backgroundColor: 'green', color: 'white' }}
                      onClick={() => handleView(row)}
                      startIcon={<Pageview />}
                    >
                      Lihat
                    </Button>
                    <Button
                      variant="danger"
                      style={{ backgroundColor: 'red', color: 'white', marginLeft: '10px' }}
                      onClick={() => handleDelete(row.id)}
                      startIcon={<DeleteForever />}
                    >
                      Delete
                    </Button>

                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>


      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <span>
          Showing {apiData.length} of {totalItems} items
        </span>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Pagination
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page + 1}
          onChange={(event, newPage) => handleChangePage(event, newPage)}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          boundaryCount={2}
          shape="rounded"
        />
      </Box>
    </Container>
  );
};

export default TableG;
