import { Button, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import  topaz  from '../components/TokenPass';

const DashboardTableA = () => {
    const [votersData, setVotersData] = useState([]);

    useEffect(() => {
        topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/recent`)
          .then(response => {
            setVotersData(response.data.data);
          })
          .catch(error => {
            console.error('Error fetching voters data:', error);
          });
    }, []);

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent sx={{ fontSize: '18px', fontWeight: '800' }}>
                Data Pemilih Terbaru
            </CardContent>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nama</TableCell>
                            <TableCell>Nomor Identitas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {votersData.map((voter) => (
                            <TableRow key={voter.id}>
                                <TableCell>{voter.full_name}</TableCell>
                                <TableCell>{voter.id_card_number}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>
                            <Link to="/table-general"><Button>Lihat Semua</Button></Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default DashboardTableA