import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Radio, RadioGroup, FormControlLabel, Grid, Badge, Avatar, IconButton, Card, CardContent, Typography, TextareaAutosize, TextField, Alert } from '@mui/material';
import Photo from '@mui/icons-material/Photo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "leaflet/dist/leaflet.css";
import { format } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSnackbar } from 'notistack';
import { getUserInfo } from '../../../components/Auth';
import C1 from '../../../assets/img/c1.jpg';
import C2 from '../../../assets/img/c2.jpg';
import C3 from '../../../assets/img/c3.jpg';
import C4 from '../../../assets/img/c4.jpg';
import C5 from '../../../assets/img/c5.jpg';
import C6 from '../../../assets/img/c6.jpg';

import topaz from '../../../components/TokenPass';



const FormPemantauan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    pic_installation: '',
    pic_inspector: '',
    visit_date: '',
    inspect_date: '',
    apk_code: '',
    apk_condition: '',
    disapproval_sign: '',
    support_sign: '',
    photo: '',
    description: '',
    created_by: '',
  });
 console.log(formData);
  
 


 

  const [formErrors, setFormErrors] = useState({});
  const today = new Date();
  const visit_date = formData.visit_date;
  const inspect_date = formData.inspect_date;
const defaultVisitDate = visit_date ? new Date(visit_date) : today;
const defaultInspectDate = inspect_date ? new Date(inspect_date) : today;
const support_sign = formData.support_sign;
const disapproval_sign = formData.disapproval_sign;
const apk_condition = formData.apk_condition;







const onChange = (value, field) => {
  if (field === 'visit_date') {
    const formattedVisitDate = format(value, 'yyyy-MM-dd HH:mm:ss');
    setFormData({ ...formData, [field]: formattedVisitDate });
  } else if (field === 'inspect_date') {
    const formattedInspectDate = format(value, 'yyyy-MM-dd HH:mm:ss');
    setFormData({ ...formData, [field]: formattedInspectDate });
  } else {
    setFormData({ ...formData, [field]: value });
  }
};




  
  

  useEffect(() => {
    const user = getUserInfo();

    if (user && user.email) {
      setFormData((prevData) => ({
        ...prevData,
        created_by: user.email, 
      }));
    }
  }, []); 

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      visit_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      inspect_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }));
  }, []);
  
  
  

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, photo: file });
  };
  


  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    onChange(value, name);
  };
  
  
  

  const validateForm = () => {
    const errors = {};

    if (!formData.pic_installation || !formData.pic_inspector || !formData.visit_date || !formData.inspect_date || !formData.apk_code || !formData.apk_condition || !formData.disapproval_sign || !formData.support_sign) {
        errors.form = 'Please fill in all the required fields';

        
        enqueueSnackbar(errors.form, { variant: 'error' });
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
};

  
  
  
const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const isConfirmed = window.confirm('Are you sure you want to submit the form?');
  
    if (isConfirmed && validateForm()) {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };
  
        const response = await topaz(config);
  
        if (response.data.status === 'success') {
          enqueueSnackbar('Form submitted successfully!', { variant: 'success' });
          navigate('/data/inspection');
        } else {
          enqueueSnackbar('Failed to submit the form. Please try again.', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        enqueueSnackbar('An error occurred while submitting the form. Please try again.', { variant: 'error' });
      }
    }
  };
  
  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/inspection');
    }
  };
  
  

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
    <Grid item xs={12} sm={10} md={8}> 
      <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
        <CardContent>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={3} direction="column">
               
              
                
                
               
                <Grid item>
                  <Typography variant="h6" color="primary">PIC</Typography>
                </Grid>
              
                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.pic_installation}
                    name="pic_installation"
                    label="PIC Pemasangan"
                    fullWidth
                  />
                </Grid>
            
                
               
              
      <Grid item>
      <TextField
                    onChange={handleFormChange}
                    value={formData.pic_inspector}
                    name="pic_inspector"
                    label="PIC Pengecekan"
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Typography variant="h6" color="primary">Pemasangan & Pengecekan</Typography>
                </Grid>

                <Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      renderInput={(params) => <TextField {...params} />}
      label="Tanggal Kunjungan"
      value={defaultVisitDate}
      onChange={(newValue) => onChange(newValue, 'visit_date')}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>
                
               
      
               
                
<Grid item>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
      renderInput={(params) => <TextField {...params} />}
      label="Tanggal Pengecekan"
      value={defaultInspectDate}
      onChange={(newValue) => onChange(newValue, 'inspect_date')}
      ampm={false} 
    />
  </LocalizationProvider>
</Grid>
   

    

    <Grid item>
      <TextField
        onChange={handleFormChange}
        value={formData.apk_code}
        name="apk_code"
       
        label="Kode APK"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
        <RadioGroup
          aria-label="apk_condition"
          name="apk_condition"
          value={apk_condition}
          onChange={(e) => onChange(e.target.value, 'apk_condition')}
          row
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="body1" color="textSecondary">Kondisi APK :</Typography>
            </Grid>
            
            <Grid item>
              <FormControlLabel value="Baik" control={<Radio />} label="Baik" />
            </Grid>
            <Grid item>
              <FormControlLabel value="Kurang Baik" control={<Radio />} label="Kurang Baik" />
            </Grid>
          
          </Grid>
        </RadioGroup>
      </Grid>

    

    

    

<Grid item>
             
              <Typography variant="h6" color="primary">Informasi Tambahan</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary">Mendukung :</Typography>
            </Grid>
            <Grid item xs={12}>
        <RadioGroup
          aria-label="support_sign"
          name="support_sign"
          value={support_sign}
          onChange={(e) => onChange(e.target.value, 'support_sign')}
          
        >
         
            
            
            <Grid container spacing={2} alignItems="center">
    <Grid item>
      <FormControlLabel
        value="1"
        control={<Radio />}
        label={
          <div>
            <img src={C1} alt="Anies Rasyid Baswedan" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C4} alt="Abdul Muhaimin Iskandar" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    
    <Grid item>
      <FormControlLabel
        value="2"
        control={<Radio />}
        label={
          <div>
            <img src={C3} alt="Prabowo Subianto Djojohadikusumo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C6} alt="Gibran Rakabuming Raka" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>

    <Grid item>
      <FormControlLabel
        value="3"
        control={<Radio />}
        label={
          <div>
            <img src={C2} alt="Ganjar Pranowo" style={{ width: '100px', marginRight: '10px' }} />
            <img src={C5} alt="Mohammad Mahfud Mahmodin" style={{ width: '100px' }} />
          </div>
        }
      />
    </Grid>
    <Grid item>
      <FormControlLabel
        value="0"
        control={<Radio />}
        label="Netral"
      />
    </Grid>
  </Grid>
       
        </RadioGroup>
      </Grid>
      <Grid item>
              <Typography variant="body1" color="textSecondary">Menolak :</Typography>
            </Grid>
      <Grid item xs={12}>
      
        <RadioGroup
          aria-label="disapproval_sign"
          name="disapproval_sign"
          value={disapproval_sign}
          onChange={(e) => onChange(e.target.value, 'disapproval_sign')}
          
        >
         
            
            
         <Grid container spacing={2} alignItems="center">
         <Grid item>
              <FormControlLabel value="Ada" control={<Radio />} label="Ada" />
            </Grid>
            <Grid item>
              <FormControlLabel value="Tidak" control={<Radio />} label="Tidak" />
            </Grid>
  </Grid>
       
        </RadioGroup>
      </Grid>
     

<Grid item>
        <div style={{
          position: 'relative',
          width: '50%',
          border: '1px solid #c4c4c4',
          borderRadius: '4px',
          padding: '16.5px 14px'
        }}>
          <div style={{
            position: 'absolute',
            top: '-11px',
            left: '-12px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            background: 'white',
            padding: '8px',
            color: '#9a9a9a',
            transform: 'translate(14px, -9px) scale(0.75)'
          }}>Foto APK</div>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <>
                <input
                  id='photo-upload'
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                />
                <label htmlFor='photo-upload'>
                  <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                </label>
              </>
            }
          >
            <Avatar src={typeof formData.photo === 'string' ? formData.photo : (formData.photo instanceof File ? URL.createObjectURL(formData.photo) : '')} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>Foto APK</Avatar>
          </Badge>
        </div>
      </Grid>

      <Grid item>
      <Grid item>
              <Typography variant="body1" color="textSecondary">Keterangan :</Typography>
            </Grid>
  <TextareaAutosize
    onChange={handleFormChange}
    value={formData.description}
    name="description"
    aria-label="empty textarea"
    placeholder="Deskripsi"
    minRows={3}
    style={{ width: '100%' }}
  />
</Grid>

   

<Grid item>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
    <Button variant="outlined" onClick={handleCancel}>
                  Batal
                </Button>
    <Button variant="contained" type="submit">
        Kirim
      </Button>
      
      </Box>
    </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormPemantauan;