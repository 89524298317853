import { Card, CardContent, Grid, MenuItem, TextField, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Maps from './Maps'
import CardCount from './CardCount'
import CardDiagram from './CardDiagram'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getDataVotersAnalytics, getDataVotersAnalyticsKabupaten, getKabupaten } from '../../../utils/ApiList'

const select = [
    { label: 'Semua', value: 0 },
    { label: 'Anies & Muhaimin', value: 1 },
    { label: 'Ganjar & Mahfud', value: 3 },
    { label: 'Prabowo & Gibran', value: 2 }
]



const VotersAnalyticsPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [isProvince, setIsProvince] = useState(true)
    const [paslon, setPaslon] = useState(0)
    const [kabupaten, setKabupaten] = useState(0)
    const [dataKabupaten, setDataKabupaten] = useState([])
    const handleChangeTingkat = (e) => setIsProvince(e.target.value)
    const handleChangePaslon = (e) => setPaslon(e.target.value)
    const handleChangeKabupaten = (e) => setKabupaten(e.target.value)

    useEffect(() => {
        getKabupaten('?page=1&limit=100')
            .then(response => setDataKabupaten(response.data.data))
    }, [])
    
    useEffect(() => {
        if (isProvince || kabupaten === 0) {
            getDataVotersAnalytics(`?direction=${paslon}`)
                .then(response => {
                    setData(response.data.data)
                })
        } else {
            getDataVotersAnalyticsKabupaten(`?direction=${paslon}&name=${kabupaten}`)
                .then(response => {
                    setData(response.data.data)
                })
        }
    }, [paslon, kabupaten, isProvince])

    const handleGoBack = () => {
        navigate('/analytics');
    };
    
    return (
        <Grid container spacing={{ xs: 1, md: 3 }}>
            <Grid item xs={12}>
                <Card sx={{ boxShadow: 'none', borderRadius: '8px' }}>
                    <CardContent sx={{ paddingBottom: '16px !important', fontSize: '20px', fontWeight: '500' }}>
                    <Grid item>
                    <IconButton style={{ backgroundColor: 'red' }} onClick={handleGoBack}>
    <ArrowBackIcon style={{ color: 'white' }} />
  </IconButton>
              </Grid>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
                       
                            <Grid item>Analisis Dukungan Masyarakat</Grid>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={{ xs: 1, md: 3 }}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant='outlined'
                                            label='Tingkat'
                                            value={isProvince}
                                            onChange={handleChangeTingkat}
                                            fullWidth
                                            select>
                                            <MenuItem value={true}>Provinsi</MenuItem>
                                            <MenuItem value={false}>Kabupaten</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant='outlined'
                                            label='Paslon'
                                            value={paslon}
                                            onChange={handleChangePaslon}
                                            fullWidth
                                            select>
                                            {select.map(x =>
                                                <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
                                            )}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {!isProvince &&
                <Grid item xs={12}>
                    <Card sx={{ boxShadow: 'none', borderRadius: '8px' }}>
                        <CardContent sx={{ paddingBottom: '16px !important', fontSize: '20px', fontWeight: '500' }}>
                            <TextField
                                variant='outlined'
                                label='Kabupaten'
                                value={kabupaten}
                                onChange={handleChangeKabupaten}
                                fullWidth
                                select>
                                <MenuItem value={0}>Semua</MenuItem>
                                {dataKabupaten.map(x =>
                                    <MenuItem key={x.id} value={x.name}>{x.name}</MenuItem>
                                )}
                            </TextField>
                        </CardContent>
                    </Card>
                </Grid>
            }
            <Grid item xs={12} lg={4}>
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12} md={6} lg={12}>
                        <CardCount type='penduduk' total={data.maps && data.maps.reduce((sum, a) => sum + Number(a.total), 0) || 0} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12}>
                        <CardCount type='kabupaten' total={data.maps && data.maps.length || 0} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Maps data={data.maps || []} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={12 / 5}>
                <CardDiagram title='Sebaran Dukungan' data={data.supportDirection || []} isProvince={isProvince || kabupaten === 0} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={12 / 5}>
                <CardDiagram title='Sebaran Pendukung Berdasarkan Usia' data={data.age || []} isProvince={isProvince || kabupaten === 0} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={12 / 5}>
                <CardDiagram title='Sebaran Pendukung Berdasarkan Jenis Kelamin' data={data.gender || []} isProvince={isProvince || kabupaten === 0} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={12 / 5}>
                <CardDiagram title='Sebaran Pendukung Berdasarkan Pendidikan' data={data.education || []} isProvince={isProvince || kabupaten === 0} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={12 / 5}>
                <CardDiagram title='Sebaran Pendukung Berdasarkan Pekerjaan' data={data.occupation || []} distinct={data.distinctOccupation || []} isProvince={isProvince || kabupaten === 0} />
            </Grid>
        </Grid>
    )
}

export default VotersAnalyticsPage