import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Box, CircularProgress, Button, Radio, RadioGroup, FormControlLabel, Grid, Badge, Avatar, IconButton, Card, CardContent, Typography, Select, MenuItem, TextField, Alert } from '@mui/material';
import Photo from '@mui/icons-material/Photo';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import L from 'leaflet';
import markerIcon from '../../../assets/leaflet/images/marker-icon.png';
import "leaflet/dist/leaflet.css";
import { format } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSnackbar } from 'notistack';
import { getUserInfo } from '../../../components/Auth';
import C1 from '../../../assets/img/c1.jpg';
import C2 from '../../../assets/img/c2.jpg';
import C3 from '../../../assets/img/c3.jpg';
import C4 from '../../../assets/img/c4.jpg';
import C5 from '../../../assets/img/c5.jpg';
import C6 from '../../../assets/img/c6.jpg';
import topaz from '../../../components/TokenPass';
import { getLocation } from '../../../components/GetLocation';



const FormPemilih = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    visit_date: '',
    gender: '',
    date_of_birth: '',
    installation_willingness_if_gp: '',
    count_equipment_given_if_gp: '',
    dusun: '',
    kelurahan: '',
    kecamatan: '',
    kabupaten: '',
    google_maps_link: '',
    province: '',
    religion: '',
    occupation: '',
    phone_number: '',
    email: '',
    support_direction: '',
    person_photo_if_gp: '',
    full_name: '',
    type_equipment_given_if_gp: '',
    id_card_photo_if_gp: '',
    id_card_number: '',
    family_card_number: '',
    rt: '',
    rw: '',
    created_by: '',
    longitude: '',
    latitude: '',
    education: '',
  });
  // console.log(formData);





  const [mapCoordinates, setMapCoordinates] = useState({ lat: '', lng: '' });
  const [formErrors, setFormErrors] = useState({});
  const today = new Date();
  const visit_date = formData.visit_date;
  const defaultVisitDate = visit_date ? new Date(visit_date) : today;
  const gender = formData.gender;


  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [kabupatenList, setKabupatenList] = useState([]);
  const [selectedKabupaten, setSelectedKabupaten] = useState('');

  const [kecamatanList, setKecamatanList] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');

  const [kelurahanList, setKelurahanList] = useState([]);
  const [selectedKelurahan, setSelectedKelurahan] = useState('');

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/province/all`);
        if (response.data.status === 'success') {
          setProvinces(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };


    fetchProvinces();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      setSelectedKabupaten(null);
      setSelectedKecamatan(null);
      setSelectedKelurahan(null);
      const fetchKabupaten = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten?province_id=${selectedProvince.id}`);
          if (response.data.status === 'success') {
            setKabupatenList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kabupaten:', error);
        }
      };

      fetchKabupaten();
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedKabupaten) {
      setSelectedKecamatan(null);
      setSelectedKelurahan(null);
      const fetchKecamatan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kecamatan/kabupaten-id/${selectedKabupaten.id}`);
          if (response.data.status === 'success') {
            setKecamatanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching kecamatan:', error);
        }
      };

      fetchKecamatan();
    }
  }, [selectedKabupaten]);

  useEffect(() => {
    if (selectedKecamatan) {
      setSelectedKelurahan(null);
      const fetchKelurahan = async () => {
        try {
          const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/kelurahan?kecamatan_id=${selectedKecamatan.id}`);
          if (response.data.status === 'success') {
            setKelurahanList(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching Kelurahan:', error);
        }
      };

      fetchKelurahan();
    }
  }, [selectedKecamatan]);


  const onChange = (value, field) => {
    if (field === 'visit_date') {
      const formattedVisitDate = format(value, 'yyyy-MM-dd HH:mm:ss');
      setFormData({ ...formData, [field]: formattedVisitDate });
    } else if (field === 'date_of_birth') {
      const formattedDateOfBirth = format(new Date(value.setHours(0, 0, 0, 0)), 'yyyy-MM-dd HH:mm:ss');
      setFormData({ ...formData, [field]: formattedDateOfBirth });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };




  const [acceptEULA, setAcceptEULA] = useState(false);

  const handleSupportDirectionChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === '0') {
      setFormData({ ...formData, support_direction: selectedValue, installation_willingness_if_gp: '0' });
      setAcceptEULA(false);
    } else {
      setFormData({ ...formData, support_direction: selectedValue });
      setFormData((prevData) => ({
        ...prevData,
        installation_willingness_if_gp: '0',
      }));
      setAcceptEULA(false);
    }
  };

  const handleEULAChange = (e) => {
    const value = e.target.value;

    if (value === 'false' && formData.support_direction === '0') {
      setFormData({ ...formData, installation_willingness_if_gp: '0' });
      setAcceptEULA(false);
    } else if (value === 'true' && formData.support_direction === '0') {
      setFormData({ ...formData, installation_willingness_if_gp: '1' });
      setAcceptEULA(true);
    }
  };




  const mapRef = useRef();
  const maps = useRef();
  let marker = null;
  const markerRef = useRef();
  const [loadingLocation, setLoadingLocation] = useState(false);

  useEffect(() => {
    maps.current = L.map(mapRef.current, {
      center: [-8.633, 116.267],
      zoom: 9
    })
    console.log(maps.current)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(maps.current)
    maps.current.on('click', (e) => {
      const { lat, lng } = e.latlng
      setMapCoordinates({ lat, lng })

      if (markerRef.current) {
        markerRef.current.setLatLng([lat, lng]);
      } else {
        const icon = L.icon({
          iconUrl: markerIcon,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })

        markerRef.current = L.marker([lat, lng], { icon }).addTo(maps.current)
      }
    })

    return () => maps.current.remove();
  }, [])


  const handleGetLocation = async () => {
    try {
      setLoadingLocation(true);
      const url = formData.google_maps_link;

      if (!url) {
        enqueueSnackbar('Mohon masukkan URL google maps!', { variant: 'warning' });
        setLoadingLocation(false);
        return;
      }

      const location = await getLocation(url);
      console.log(location)
      setMapCoordinates({
        lat: location.latitude,
        lng: location.longitude,
      });

      if (markerRef.current) {
        markerRef.current.setLatLng([location.latitude, location.longitude]);
      } else {
        const icon = L.icon({
          iconUrl: markerIcon,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
        markerRef.current = L.marker([location.latitude, location.longitude], { icon }).addTo(maps.current)
      }

      setFormData({
        ...formData,
        latitude: location.latitude,
        longitude: location.longitude,
      });

      enqueueSnackbar('Lokasi di dapatkan', { variant: 'success' });
      setLoadingLocation(false);
    } catch (error) {
      setLoadingLocation(false);
      enqueueSnackbar('URL tak sesuai persyaratan', { variant: 'error' });
    }
  };
  useEffect(() => {
    const user = getUserInfo();

    if (user && user.email) {
      setFormData((prevData) => ({
        ...prevData,
        created_by: user.email,
      }));
    }
  }, []);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      visit_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      province: selectedProvince ? selectedProvince.name : '',
      kabupaten: selectedKabupaten ? selectedKabupaten.name : '',
      kecamatan: selectedKecamatan ? selectedKecamatan.name : '',
      kelurahan: selectedKelurahan ? selectedKelurahan.name : '',
      latitude: mapCoordinates.lat,
      longitude: mapCoordinates.lng,
    }));
  }, [mapCoordinates.lat, mapCoordinates.lng, selectedProvince, selectedKabupaten, selectedKecamatan, selectedKelurahan]);
  const handlePersonPhotoChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, person_photo_if_gp: file });
  };
  const handleIdCardPhotoChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, id_card_photo_if_gp: file });
  };
  const [idCardValidationResult, setIdCardValidationResult] = useState(null);
  const validateIdCardNumber = async (idCardNumber) => {
    try {
      const response = await topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/validate-ktp/${idCardNumber}`);
      return response.data;
    } catch (error) {
      console.error('Error validating ID card number:', error);
      return { status: 'error', message: 'Unable to validate ID card number' };
    }
  };
  const handleIdCardNumberChange = async (e) => {
    const idCardNumber = e.target.value;
    setFormData({ ...formData, id_card_number: idCardNumber });

    if (idCardNumber.length > 0) {
      console.log('Checking API for ID card validation...');
      const validationResponse = await validateIdCardNumber(idCardNumber);

      console.log('Validation response:', validationResponse);

      if (validationResponse.status === 'error') {
        setIdCardValidationResult({ status: 'error', message: 'Nomor KTP sudah digunakan.' });
      } else {
        setIdCardValidationResult(null);
      }
    } else {
      setIdCardValidationResult(null);
    }
  };
  const handleFormChange = (e) => {
    const numericFields = ['phone_number', 'count_equipment_given_if_gp', 'rt', 'rw', 'family_card_number', 'id_card_number'];

    if (numericFields.includes(e.target.name)) {

      const numericValue = e.target.value.replace(/\D/g, '');
      setFormData({ ...formData, [e.target.name]: numericValue });
    } else if (e.target.name === 'lat' || e.target.name === 'lng') {
      const newCoordinates = {
        lat: e.target.name === 'lat' ? e.target.value : formData.lat,
        lng: e.target.name === 'lng' ? e.target.value : formData.lng,
      };

      setFormData((prevData) => ({
        ...prevData,
        ...newCoordinates,
      }));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const validateForm = () => {
    const errors = {};


    if (!formData.visit_date || !formData.longitude || !formData.latitude || !formData.gender || !formData.date_of_birth || !formData.full_name || !formData.id_card_number || !formData.family_card_number || !formData.phone_number || !formData.email || !formData.rt || !formData.rw || !formData.dusun || !formData.kelurahan || !formData.kecamatan || !formData.kabupaten || !formData.province || !formData.religion || !formData.education || !formData.occupation || !formData.support_direction) {
      errors.form = 'Mohon isi semua kolom!';
      enqueueSnackbar(errors.form, { variant: 'error' });
    }


    if (idCardValidationResult && idCardValidationResult.status === 'error') {
      errors.id_card_number = idCardValidationResult.message;
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      const confirmed = window.confirm('Data akan dikirim, apakah data sudah baner?');
      if (confirmed) {
        try {
          const data = new FormData();

          for (const key in formData) {
            if (formData[key] || key === 'installation_willingness_if_gp') {
              data.append(key, formData[key]);
            }
          }

          const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/api/general-election-voters`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          };

          const response = await topaz.request(config);


          enqueueSnackbar('Data telah terkirim', { variant: 'success' });

          navigate('/data/voters');
        } catch (error) {
          enqueueSnackbar(`Data gagal dikirim: ${error.message}`, { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Batal Mengirim.', { variant: 'info' });
      }
    } else {
      enqueueSnackbar('Form tidak valid, mohon isi semua kolom!', { variant: 'warning' });
    }
  };
  const handleCancel = () => {
    const confirmed = window.confirm('Are you sure you want to cancel?');
    if (confirmed) {
      navigate('/data/voters');
    }
  };
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh', paddingTop: '60px' }}>
      <Grid item xs={12} sm={10} md={8}>
        <Card style={{ margin: '20px', width: '100%', marginTop: '20px' }}>
          <CardContent>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={3} direction="column">

                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(params) => <TextField {...params} />}
                      label="Tanggal Kunjungan"
                      value={defaultVisitDate}
                      onChange={(newValue) => onChange(newValue, 'visit_date')}
                      ampm={false}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item>
                  <div ref={mapRef} style={{ height: '480px', width: '800px', margin: '0 auto' }} />
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item xs={true}>
                      <TextField
                        placeholder="Place Google Maps URL Here"
                        label="Use Google Maps URL (OPTIONAL)"
                        fullWidth
                        name="google_maps_link"
                        value={formData.google_maps_link}
                        onChange={(e) => handleFormChange(e)}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{ height: '56px', paddingLeft: '16px', paddingRight: '16px' }}
                        onClick={handleGetLocation}
                        disabled={loadingLocation}
                      >
                        {loadingLocation ? <CircularProgress size={24} /> : 'Get Location'}
                      </Button></Grid>
                  </Grid>
                </Grid>
                <Grid item container spacing={3} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      onChange={handleFormChange}
                      value={mapCoordinates.lat}
                      name="lat"
                      label="Latitude"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={handleFormChange}
                      value={mapCoordinates.lng}
                      name="lng"
                      label="Longitude"
                      fullWidth
                      disabled
                    />
                  </Grid>

                </Grid>

                <Grid item>
                  <Typography variant="h6" color="primary">Informasi Pribadi</Typography>
                </Grid>

                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.full_name}
                    name="full_name"
                    label="Nama Lengkap"
                    fullWidth
                  />
                </Grid>


                <Grid item >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Tanggal Lahir"
                      value={formData.date_of_birth ? new Date(formData.date_of_birth) : null}
                      onChange={(newValue) => onChange(newValue, 'date_of_birth')}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={gender}
                    onChange={(e) => onChange(e.target.value, 'gender')}
                    row
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography variant="body1" color="textSecondary">Jenis Kelamin :</Typography>
                      </Grid>

                      <Grid item>
                        <FormControlLabel value="Laki-laki" control={<Radio />} label="Laki-laki" />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value="Perempuan" control={<Radio />} label="Perempuan" />
                      </Grid>

                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={handleIdCardNumberChange}
                    value={formData.id_card_number}
                    name="id_card_number"
                    type="number"
                    label="Nomor KTP"
                    fullWidth
                    error={idCardValidationResult && idCardValidationResult.status === 'error'}
                    helperText={idCardValidationResult && idCardValidationResult.message}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.family_card_number}
                    name="family_card_number"
                    type="number"
                    label="Nomor KK"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Select
                    value={formData.religion || ''}
                    onChange={(e) => onChange(e.target.value, 'religion')}
                    displayEmpty
                    fullWidth
                    placeholder="Pilih Agama"
                  >
                    <MenuItem value="" disabled>
                      Pilih Agama
                    </MenuItem>

                    <MenuItem value="Islam">Islam</MenuItem>
                    <MenuItem value="Kristen">Kristen</MenuItem>
                    <MenuItem value="Budha">Budha</MenuItem>
                    <MenuItem value="Hindu">Hindu</MenuItem>
                    <MenuItem value="Lainnya">Lainnya</MenuItem>
                    <MenuItem value="Tidak Menjawab">Tidak Menjawab</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.occupation}
                    name="occupation"

                    label="Pekerjaan"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Select
                    value={formData.education || ''}
                    onChange={(e) => onChange(e.target.value, 'education')}
                    displayEmpty
                    fullWidth
                    placeholder="Pendidikan Terakhir"
                  >
                    <MenuItem value="" disabled>
                      Pendidikan Terakhir
                    </MenuItem>

                    <MenuItem value="SD">SD</MenuItem>
                    <MenuItem value="SMP">SMP</MenuItem>
                    <MenuItem value="SMA">SMA</MenuItem>
                    <MenuItem value="PERGURUAN TINGGI">PERGURUAN TINGGI</MenuItem>
                    <MenuItem value="TIDAK BERSEKOLAH">TIDAK BERSEKOLAH</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="primary">Informasi Kontak</Typography>
                </Grid>
                <Grid item >


                  <TextField
                    onChange={handleFormChange}
                    value={formData.phone_number}
                    name="phone_number"
                    type="number"
                    label="Nomor Telepon"
                    fullWidth
                  />

                </Grid>

                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.email}
                    name="email"

                    label="E-Mail"
                    fullWidth
                  />
                </Grid>



                <Grid item>
                  <Autocomplete
                    value={selectedProvince}
                    onChange={(event, newValue) => setSelectedProvince(newValue)}
                    options={provinces}
                    getOptionLabel={(option) => option ? option.name : ''}
                    renderInput={(params) => <TextField {...params} label="Pilih Provinsi" />}
                  />
                </Grid>


                <Grid item>
                  <Autocomplete
                    value={selectedKabupaten}
                    onChange={(event, newValue) => setSelectedKabupaten(newValue)}
                    options={kabupatenList}
                    getOptionLabel={(option) => option ? option.name : ''}
                    renderInput={(params) => <TextField {...params} label="Pilih Kabupaten" />}
                    disabled={!selectedProvince}
                  />
                </Grid>


                <Grid item>
                  <Autocomplete
                    value={selectedKecamatan}
                    onChange={(event, newValue) => setSelectedKecamatan(newValue)}
                    options={kecamatanList}
                    getOptionLabel={(option) => option ? option.name : ''}
                    renderInput={(params) => <TextField {...params} label="Pilih Kecamatan" />}
                    disabled={!selectedKabupaten}
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    value={selectedKelurahan}
                    onChange={(event, newValue) => setSelectedKelurahan(newValue)}
                    options={kelurahanList}
                    getOptionLabel={(option) => option ? option.name : ''}
                    renderInput={(params) => <TextField {...params} label="Pilih Kelurahan" />}
                    disabled={!selectedKecamatan}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={handleFormChange}
                    value={formData.dusun}
                    name="dusun"

                    label="Dusun"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6}>

                      <TextField
                        onChange={handleFormChange}
                        value={formData.rt}
                        name="rt"
                        type="number"
                        label="RT"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>

                      <TextField
                        onChange={handleFormChange}
                        value={formData.rw}
                        name="rw"
                        type="number"
                        label="RW"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>





                <Grid item>

                  <Typography variant="h6" color="primary">Pilih Pasangan</Typography>
                </Grid>

                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="support_direction"
                    name="support_direction"
                    value={formData.support_direction}
                    onChange={handleSupportDirectionChange}
                    row
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <div>
                              <img src={C1} alt="Anies Rasyid Baswedan" style={{ width: '100px', marginRight: '10px' }} />
                              <img src={C4} alt="Abdul Muhaimin Iskandar" style={{ width: '100px' }} />
                            </div>
                          }
                        />
                      </Grid>

                      <Grid item>
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <div>
                              <img src={C3} alt="Prabowo Subianto Djojohadikusumo" style={{ width: '100px', marginRight: '10px' }} />
                              <img src={C6} alt="Gibran Rakabuming Raka" style={{ width: '100px' }} />
                            </div>
                          }
                        />
                      </Grid>

                      <Grid item>
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label={
                            <div>
                              <img src={C2} alt="Ganjar Pranowo" style={{ width: '100px', marginRight: '10px' }} />
                              <img src={C5} alt="Mohammad Mahfud Mahmodin" style={{ width: '100px' }} />
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Tidak Memilih"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>

                </Grid>
                {formData.support_direction === '0' && (
                  <Grid item>
                    <Typography variant="body1" color="textSecondary">
                      Apakah bersedia menerima Alat Peraga Kampanye (APK)?
                    </Typography>
                    <RadioGroup
                      aria-label="accept_eula"
                      name="accept_eula"
                      value={acceptEULA}
                      onChange={handleEULAChange}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Ya"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Tidak"
                      />
                    </RadioGroup>
                  </Grid>
                )}



                {acceptEULA && (
                  <>
                    <Grid item>
                      <Typography variant="h6" color="primary">Informasi Tambahan</Typography>
                    </Grid>





                    <Grid item>
                      <div style={{
                        position: 'relative',
                        width: '50%',
                        border: '1px solid #c4c4c4',
                        borderRadius: '4px',
                        padding: '16.5px 14px'
                      }}>
                        <div style={{
                          position: 'absolute',
                          top: '-11px',
                          left: '-12px',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          background: 'white',
                          padding: '8px',
                          color: '#9a9a9a',
                          transform: 'translate(14px, -9px) scale(0.75)'
                        }}>Foto Wajah</div>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <>
                              <input
                                id='person-photo-upload'
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={handlePersonPhotoChange}
                              />
                              <label htmlFor='person-photo-upload'>
                                <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                              </label>
                            </>
                          }
                        >
                          <Avatar src={typeof formData.person_photo_if_gp === 'string' ? formData.person_photo_if_gp : (formData.person_photo_if_gp instanceof File ? URL.createObjectURL(formData.person_photo_if_gp) : '')} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>Foto Wajah</Avatar>
                        </Badge>
                      </div>
                    </Grid>

                    <Grid item>
                      <div style={{
                        position: 'relative',
                        width: '50%',
                        border: '1px solid #c4c4c4',
                        borderRadius: '4px',
                        padding: '16.5px 14px'
                      }}>
                        <div style={{
                          position: 'absolute',
                          top: '-11px',
                          left: '-12px',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          background: 'white',
                          padding: '8px',
                          color: '#9a9a9a',
                          transform: 'translate(14px, -9px) scale(0.75)'
                        }}>Foto KTP</div>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <>
                              <input
                                id='id-card-photo-upload'
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={handleIdCardPhotoChange}
                              />
                              <label htmlFor='id-card-photo-upload'>
                                <IconButton sx={{ background: 'white', color: 'primary.main', '&:hover': { background: 'white', color: 'primary.dark' } }} component="span"><Photo /></IconButton>
                              </label>
                            </>
                          }
                        >
                          <Avatar src={typeof formData.id_card_photo_if_gp === 'string' ? formData.id_card_photo_if_gp : (formData.id_card_photo_if_gp instanceof File ? URL.createObjectURL(formData.id_card_photo_if_gp) : '')} sx={{ width: '200px', height: '200px', borderRadius: '8px' }}>Foto KTP</Avatar>
                        </Badge>
                      </div>
                    </Grid>

                    <Grid item>
                      <Select
                        value={formData.type_equipment_given_if_gp || ''}
                        onChange={(e) => onChange(e.target.value, 'type_equipment_given_if_gp')}
                        displayEmpty
                        fullWidth
                        placeholder="Tipe APK"
                      >
                        <MenuItem value="" disabled>
                          Tipe APK
                        </MenuItem>

                        <MenuItem value="Baliho Bambu 300x400">Baliho Bambu 300x400</MenuItem>
                        <MenuItem value="Billboard">Billboard</MenuItem>
                        <MenuItem value="Umbul-Umbul 70-300">Umbul-Umbul 70-300</MenuItem>
                        <MenuItem value="Spanduk 300x100">Spanduk 300x100</MenuItem>
                        <MenuItem value="Spanduk 700x100">Spanduk 700x100</MenuItem>
                        <MenuItem value="Spanduk Warung">Spanduk Warung</MenuItem>
                      </Select>
                    </Grid>

                    <Grid item>
                      <TextField
                        onChange={handleFormChange}
                        value={formData.count_equipment_given_if_gp}
                        name="count_equipment_given_if_gp"
                        type="number"
                        label="Jumlah APK"
                        fullWidth
                      />
                    </Grid>
                  </>

                )
                }

                <Grid item>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
                    <Button variant="outlined" onClick={handleCancel}>
                      Batal
                    </Button>
                    <Button variant="contained" type="submit">
                      Kirim
                    </Button>

                  </Box>
                </Grid>

              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormPemilih;