import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import markerIcon from '../assets/leaflet/images/marker-icon.png';

const MapSelection = ({ selectedLocation, locations }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markers = useRef([]);

  useEffect(() => {
    if (!mapRef.current || !locations.length) {
      return;
    }

    if (mapInstance.current) {
      mapInstance.current.remove();
    }

    const map = L.map(mapRef.current);
    map.setView([-8.652933, 117.361648], 8);
    mapInstance.current = map;

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; OpenStreetMap contributors'
    }).addTo(map);

   
    markers.current = locations.map(location => {
      const { longitude, latitude, kecamatan } = location;
      const coordinates = [latitude, longitude];

      const marker = L.marker(coordinates, { icon: L.icon({ iconUrl: markerIcon }) }).addTo(map);
      marker.bindPopup(`Kecamatan: ${kecamatan}<br>Latitude: ${latitude}<br>Longitude: ${longitude}`);

      marker.on('click', () => {
        map.flyTo(coordinates, 15); 
      });

      return marker;
    });

  }, [locations]);

  useEffect(() => {
   
    markers.current.forEach(marker => {
      if (
        selectedLocation &&
        marker.getLatLng().lat === selectedLocation.latitude &&
        marker.getLatLng().lng === selectedLocation.longitude
      ) {
        marker.setIcon(
          L.icon({
            iconUrl: markerIcon,
            iconSize: [40, 65],
            iconAnchor: [20, 41],
          })
        );
        marker.openPopup();
      } else {
        marker.setIcon(L.icon({ iconUrl: markerIcon })); 
      }
    });
  }, [selectedLocation]);

  return (
    <div
      ref={mapRef}
      style={{ height: '480px', width: '800px', margin: '0 auto' }}
    />
  );
};

export default MapSelection;