import topaz from "../components/TokenPass";

export const checkUser = () => topaz.get(`${process.env.REACT_APP_API_URL}/api/auth/check-token`)
export const getKabupaten = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/kabupaten${query}`)


export const getDataVoters = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters${query}`)
export const getDataVotersAnalytics = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/analytics${query}`)
export const getDataVotersAnalyticsKabupaten = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/general-election-voters/analytics/kabupaten${query}`)
export const getDataApkAnalytics = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/analytics${query}`)
export const getDataApkAnalyticsKabupaten = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/analytics/kabupaten${query}`)



export const getDataInstall = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation${query}`)
export const getDataInstallAnalytics = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/analytics${query}`)
export const getDataInstallAnalyticsKabupaten = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-installation/analytics/kabupaten${query}`)

export const getDataUtil = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization${query}`)
export const getDataUtilAnalytics = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization/analytics${query}`)
export const getDataUtilAnalyticsKabupaten = (query) => topaz.get(`${process.env.REACT_APP_API_URL}/api/campaign-tool-utilization/analytics/kabupaten${query}`)
