import { Moving } from '@mui/icons-material'
import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'

const DashboardCardA = () => {
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <div style={{ fontSize: '14px', fontWeight: '600', fontFamily: '"Public Sans", sans-serif' }}>Total Data Pemiih</div>
                <div style={{ fontSize: '32px', fontWeight: 'bold' }}>393592</div>
                <Grid container sx={{ fontSize: '14px' }}>
                    <Grid item xs={true}>
                        Terbaru
                    </Grid>
                    <Grid item>
                        <Moving sx={{ color: 'green' }} />
                    </Grid>
                    <Grid item>
                        60023
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default DashboardCardA