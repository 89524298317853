import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'

const style = {
    error: {
        color: 'white',
        backgroundColor: red['A400'],
        '&:hover': {
            color: 'white',
            backgroundColor: red['A700'],
        }
    }
}

const DialogConfirmation = ({ type, header, desc, buttonDesc, handleClose, confirm }) => {
    const color = type || ''
    return (
        <Dialog open={true} onClose={handleClose}        >
            <DialogTitle disableTypography sx={{ fontSize: '20px', fontWeight: '700' }}>
                {header}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {desc}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>
                    {buttonDesc.cancel}
                </Button>
                <Button sx={style[color]} variant='contained' onClick={confirm}>
                    {buttonDesc.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogConfirmation